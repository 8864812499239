import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'constants/';
import { PrivateRoute } from 'routes/PrivateRoute';

import MainLayout from 'view/layouts/MainLayout';
import FullLayout from 'view/layouts/FullLayout';
import LoginLayout from 'view/layouts/LoginLayout';
import DataLoaderLayout from 'view/layouts/DataLoaderLayout';

import HomePage from 'view/pages/Home';
import LoginPage from 'view/pages/Login';
import CreateAccount from 'view/pages/CreateAccount';
import Checkout from 'view/pages/Checkout';
import PaymentOrder from 'view/pages/PaymentOrder';
import PaymentConfirmation from 'view/pages/PaymentConfirmation';
import PageNotFound from 'view/pages/PageNotFound';
import ForgetPasswordPage from 'view/pages/ForgetPassword';

function App() {
  return (
    <Routes>
      <Route element={<LoginLayout />}>
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.CREATE_ACCOUNT} element={<CreateAccount />} />
        <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPasswordPage />} />
      </Route>

      <Route element={<PrivateRoute />}>
        <Route element={<DataLoaderLayout />}>
          <Route element={<MainLayout showStudentList />}>
            <Route path={ROUTES.HOME} element={<HomePage />} />
          </Route>
          <Route element={<MainLayout showStudentList={false} />}>
            <Route path={ROUTES.CHECKOUT} element={<Checkout />} />
            <Route path={ROUTES.PAYMENT} element={<PaymentOrder />} />
            <Route path={ROUTES.PAYMENT_CONFIRMATION} element={<PaymentConfirmation />} />
          </Route>
        </Route>
        <Route element={<FullLayout />}>
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
