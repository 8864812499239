import { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { ROUTES } from 'constants/';
import { useAuth } from 'hooks';
import { useAppDispatch } from 'store/hooks';
import { setAccountInfo, setAuthenticatedUser, setIsLoading } from 'features/authSlice';
import { databaseService } from 'services/database';
import { authService } from 'services/auth';

import { AppLoader } from 'components';

export const PrivateRoute = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { isAuthenticated, user, isLoading } = useAuth();

  useEffect(() => {
    let unsubscribeDatabase: () => void | undefined;

    const unsubscribeAuth = authService.onAuthStateChanged((user) => {
      if (user) {
        unsubscribeDatabase = databaseService.listenAccountInfo(user.uid, (accountInfo) => {
          dispatch(setAccountInfo(accountInfo));
        });
      }

      dispatch(setAuthenticatedUser(user));
      dispatch(setIsLoading(false));
    });

    return () => {
      unsubscribeDatabase?.();
      unsubscribeAuth?.();
    };
  }, [dispatch]);

  // the auth state is loading...
  if (isLoading) {
    return <AppLoader />;
  }

  // the user is authorized, show the route
  if (isAuthenticated && user) {
    return <Outlet />;
  }

  // otherwise, if the user is not authorized, redirect to the login page
  return <Navigate to={ROUTES.LOGIN} replace state={{ from: location }} />;
};
