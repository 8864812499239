import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Container } from 'react-bootstrap';
import { Stepper, Step } from 'react-form-stepper';

import { ROUTES } from 'constants/';
import { useAuth } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectCartState, selectIsErrorCart, selectIsLoadingCart } from 'features/shoppingSlice';
import { AppLoader } from 'components';
import { ReviewOrder } from './ReviewOrder';
import { CustomerRegistration } from './CustomerRegistration';
import { OperationError } from '../OperationError';

import './styles.scss';

const Checkout = () => {
  const navigate = useNavigate();
  const { isLoading } = useAuth();

  const cartState = useAppSelector(selectCartState);
  const isLoadingCart = useAppSelector(selectIsLoadingCart);
  const isErrorCart = useAppSelector(selectIsErrorCart);

  useEffect(() => {
    if (!isLoading && !isLoadingCart && cartState.products.length === 0) {
      navigate(ROUTES.HOME);
    }
  }, [cartState.products, isLoading, isLoadingCart, navigate]);

  useEffect(() => {
    document.body.scrollTop = 0;
  }, []);

  return (
    <div className="div-center">
      <div className="row">
        <Col className="p-4 p-md-8">
          <Container className="checkout-container">
            {isLoading || isLoadingCart ? (
              <AppLoader />
            ) : isErrorCart ? (
              <OperationError />
            ) : (
              <>
                <Container className="px-0 mb-6">
                  <Stepper activeStep={0} className="p-0">
                    <Step label="Pedido" className="rounded-stepper" onClick={() => true} />
                    <Step label="Pagamento" className="rounded-stepper" disabled />
                    <Step label="Confirmação" className="rounded-stepper" disabled />
                  </Stepper>
                </Container>
                <h2 className="mb-5 fw-bolder">Finalização do Pedido</h2>
                <section className="mb-8 checkout-section" data-title="1. Revise o seu pedido">
                  <ReviewOrder />
                </section>
                <section className="mb-8 checkout-section" data-title="2. Dados do comprador">
                  <CustomerRegistration />
                </section>
              </>
            )}
          </Container>
        </Col>
      </div>
    </div>
  );
};

export default Checkout;
