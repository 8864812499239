import { useCallback, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Offcanvas, Table } from 'react-bootstrap';
import { faCartShopping, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toCurrency } from 'utils';
import { useAuth } from 'hooks';
import { ROUTES } from 'constants/';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { databaseService } from 'services/database';
import { selectShowCart, selectCartState, setShowCart } from 'features/shoppingSlice';
import { AmountSelector } from 'components/AmountSelector';

import './styles.scss';

export const AppCart = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAuth();

  const showCart = useAppSelector(selectShowCart);
  const cartState = useAppSelector(selectCartState);

  const totalCartPrice = useMemo(() => {
    return cartState.products.reduce((result, prod) => {
      result += prod.amount * prod.price;
      return result;
    }, 0.0);
  }, [cartState.products]);

  const handleClose = useCallback(() => dispatch(setShowCart(false)), [dispatch]);

  const updateProductAmount = useCallback(
    async (productCode: string, studentId: string, newAmount: number) => {
      try {
        await databaseService.updateAmountProductCart(user?.uid ?? '', productCode, studentId, new Date(), newAmount);
      } catch (error) {
        console.log(error);
      }
    },
    [user?.uid]
  );

  const deleteProduct = useCallback(
    async (productCode: string, studentId: string) => {
      try {
        await databaseService.deleteProductCart(user?.uid ?? '', productCode, studentId, new Date());
      } catch (error) {
        console.log(error);
      }
    },
    [user?.uid]
  );

  const goToCheckout = useCallback(() => {
    navigate(ROUTES.CHECKOUT);
    handleClose();
  }, [navigate, handleClose]);

  return (
    <Offcanvas show={showCart} placement="end" onHide={handleClose}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <FontAwesomeIcon icon={faCartShopping} className="me-4" /> Carrinho de Compras
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-2 cart-body">
        <Table>
          <thead>
            <tr>
              <th className="fw-normal">Produto</th>
              <th></th>
              <th className="fw-normal">Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cartState.products.map((product) => {
              const productCartKey = `${product.code}_${product.student.id}`;

              return (
                <tr key={productCartKey}>
                  <td className="text-center">
                    <img src={product.photoUrl} alt={product.name} className="thumb-product" />
                  </td>
                  <td>
                    <div className="text-primary fw-bold product-title">{product.name}</div>
                    <div className="fw-bolder product-student-name">{product.student.studentName}</div>
                    <div>{toCurrency(product.price)}</div>

                    <div className="d-flex flex-row justify-content-start align-items-center col-6 my-1">
                      <AmountSelector
                        id="amountToAdd"
                        type="number"
                        min={1}
                        placeholder="Quantidade"
                        className="form-control text-center col-3"
                        onChange={(val) => updateProductAmount(product.code, product.student.id, parseInt(val || '0'))}
                        value={product.amount === 0 ? '' : product.amount}
                        // disabled={updatingAmount === productCartKey}
                        size="sm"
                      />{' '}
                      <span className="ms-2 d-inline d-md-none">un.</span>
                      <span className="ms-2 d-none d-md-inline">unidade(s)</span>
                    </div>
                    <div className="text-muted p-0 my-1">
                      <div className="p-0 m-0 fw-normal product-amount">
                        {product.bookAmount * product.amount} livro(s)
                      </div>
                    </div>
                  </td>
                  <td className="fw-bolder overflow-hidden w-25">
                    <div className="product-subprice">{toCurrency(product.amount * product.price)}</div>
                  </td>
                  <td>
                    <button
                      onClick={() => deleteProduct(product.code, product.student.id)}
                      className="icon-button border-0 bg-transparent text-danger"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td className="fw-bolder fs-5 text-primary">Total</td>
              <td colSpan={3} className="text-end fs-5 text-primary product-price">
                {toCurrency(totalCartPrice)}
              </td>
            </tr>
          </tfoot>
        </Table>
        <div className="w-100 my-5">
          <Button variant="primary" className="text-white w-100" size="lg" type="button" onClick={goToCheckout}>
            Finalizar Compra
          </Button>
        </div>
        <div className="text-center w-100 my-3">
          <Link to={ROUTES.HOME} onClick={handleClose} className="text-center w-100">
            ou continuar comprando
          </Link>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};
