import { Outlet } from 'react-router-dom';
import { AppNavbar } from 'components/';
import { AppCart } from 'components/AppCart';

import './styles.scss';

interface MainLayoutProps {
  showStudentList?: boolean;
}

const MainLayout = ({ showStudentList = true }: MainLayoutProps) => {
  return (
    <div className="main-layout">
      <AppNavbar showStudentList={showStudentList} />
      <main className="h-100">
        <Outlet />
      </main>
      <AppCart />
    </div>
  );
};

export default MainLayout;
