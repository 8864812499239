import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  getAuth,
  User as FirebaseUser,
  UserCredential,
  sendPasswordResetEmail,
} from 'firebase/auth';
import { User } from 'types';
import { initFirebase } from '../initFirebase';

export interface IFirebaseAuthService {
  createAuthUser: (email: string, password: string) => Promise<UserCredential>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<UserCredential>;
  signOut: () => Promise<void>;
  onAuthStateChanged: (observer: (a: User | null) => void, error?: (a: Error) => void) => () => void;
  forgetPassword: (email: string) => Promise<void>;
}

initFirebase();
const auth = getAuth();

const createAuthUser = async (email: string, password: string): Promise<UserCredential> =>
  createUserWithEmailAndPassword(auth, email, password);

const signInEmail = async (email: string, password: string): Promise<UserCredential> =>
  signInWithEmailAndPassword(auth, email, password);

const signOutEmail = async (): Promise<void> => signOut(auth);

const authStateChanged = (observer: (a: User | null) => void, error?: (a: Error) => void): (() => void) => {
  const observ = (fbUser: FirebaseUser | null): void => {
    if (!fbUser) {
      observer(null);
      return;
    }

    const user: User = {
      uid: fbUser.uid,
      email: fbUser.email ?? '',
    };

    observer(user);
  };

  const unsubscribe: () => void = onAuthStateChanged(auth, observ, error);
  return unsubscribe;
};

const forgetPassword = (email: string): Promise<void> => sendPasswordResetEmail(auth, email);

export const firebaseAuthService: IFirebaseAuthService = {
  createAuthUser,
  signInWithEmailAndPassword: signInEmail,
  signOut: signOutEmail,
  onAuthStateChanged: authStateChanged,
  forgetPassword,
};
