import { Product, ProductCart, Student } from 'types';

/**
 * Returns a function that calls all the unsubscribers passed as parameters.
 * Arrays of unsubscribers are supported too.
 */
export function composeUnsubscribers(unsubscribers: Array<() => void>) {
  unsubscribers = unsubscribers.flat();
  return () => {
    for (const unsubscribe of unsubscribers) unsubscribe();
  };
}

export function toCurrency(value: number): string {
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

export function validateCpf(cpf: string | undefined): boolean {
  if (!cpf) return false;

  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '') return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;

  // Valida 1o digito
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;

  // Valida 2o digito
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
}

export function validateExpiration(expiration: string | undefined): boolean {
  if (!expiration) return false;

  if (expiration.length !== 4) return false;

  const month = expiration.substring(0, 2);
  const year = expiration.substring(2, 4);

  const numericMonth = parseInt(month);
  const numericYear = parseInt(year);

  if (isNaN(numericMonth) || numericMonth < 1 || numericMonth > 12) return false;

  if (isNaN(numericYear) || numericYear + 2000 < new Date().getFullYear()) return false;

  return true;
}

export function createNewProductCart(uid: string, product: Product, amount: number, student: Student) {
  const productCart: ProductCart = {
    id: '',
    uid,
    code: product.code,
    name: product.name,
    bookType: product.bookType,
    bookAmount: product.bookAmount,
    price: product.price,
    photoUrl: product.photoUrl,
    amount,
    student: student,
    bookModel: product.bookModel,
  };

  return productCart;
}
