import { getDatabase, DatabaseReference, ref, DataSnapshot, get, update, onValue } from 'firebase/database';
import { initFirebase } from 'services/firebase/initFirebase';
import { BookModel, BookType, Cart, Product, ProductCart, Student } from 'types';
import { BASE_DATABASE_URL } from '..';

initFirebase();
const database = getDatabase();

export const getAvailableProductList = async (schoolCode: string): Promise<Array<Product>> => {
  if (!schoolCode?.trim?.()) {
    throw new Error('invalid-school-code');
  }

  const availableProductsRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/sales/${schoolCode}`);
  const availableProductsSnapshot = await get(availableProductsRef);

  const promiseQueryProducts: Array<Promise<DataSnapshot>> = [];
  const additionalValueByProd: { [schoolCode: string]: number } = {};

  availableProductsSnapshot.forEach((snapAvailableProd) => {
    const availableProd = snapAvailableProd.val();

    const productCode: string = availableProd['productCode'];
    const additionalValue: number = availableProd['additionalValue'];

    const finalDateString: string | undefined = availableProd['finalDate'];

    if (finalDateString) {
      try {
        const finalDate = new Date(JSON.parse(finalDateString));

        if (finalDate >= new Date()) {
          // save additional value to add to final product price
          additionalValueByProd[productCode] = additionalValue;

          promiseQueryProducts.push(get(ref(database, `${BASE_DATABASE_URL}/products/${productCode}`)));
        }
      } catch (err) {
        console.log(err);
      }
    }
  });

  const productQueryResults = await Promise.all(promiseQueryProducts);

  const productList: Array<Product> = productQueryResults.map((snap) => {
    const res = snap.val();

    let bookModel: BookModel = BookModel.web;

    if (res['bookModel'] === 'coletivo') {
      bookModel = BookModel.collective;
    } else if (res['bookModel'] === 'desenho') {
      bookModel = BookModel.draw;
    } else {
      bookModel = BookModel.web;
    }

    const product: Product = {
      code: res['code'],
      name: res['name'],
      price: res['basePrice'],
      bookType: res['type'] === 'mole' ? BookType.softCover : BookType.hardCover,
      bookAmount: res['amount'],
      bookModel,
      productDescription: res['productDescription'],
      salesDescription: res['salesDescription'],
      photoUrl: res['photoUrl'],
      badge: res['badge'],
    };

    // additional price from the school
    product.price += additionalValueByProd[product.code];

    return product;
  });

  return productList;
};

export const listenProductCart = (
  uid: string,
  callback: (cart: Cart) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => {
  if (!uid?.trim?.()) {
    if (errorCallback) {
      errorCallback(new Error('invalid-uid'));
    }
    return () => null;
  }

  const cartRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/carts/${uid}`);

  const unsubscribe = onValue(
    cartRef,
    (snapshot: DataSnapshot) => {
      const cartData = snapshot.val();
      const products: Array<ProductCart> = [];

      try {
        snapshot.child('products').forEach((childSnapshot) => {
          const data = childSnapshot.val();

          const student: Student = {
            id: data['studentId'],
            parentUid: uid,
            schoolCode: data['schoolCode'],
            schoolName: data['schoolName'],
            studentCode: data['studentCode'],
            studentClass: data['studentClass'],
            studentName: data['studentName'],
            studentFullname: data['studentFullname'],
            biography: data['biography'],
            photoUrl: data['studentPhotoUrl'],
            imagePermission: data['imagePermission'],
            disableBiography: data['disableBiography'] ?? false,
          };

          let bookModel: BookModel = BookModel.web;

          if (data['bookModel'] === 'coletivo') {
            bookModel = BookModel.collective;
          } else if (data['bookModel'] === 'desenho') {
            bookModel = BookModel.draw;
          } else {
            bookModel = BookModel.web;
          }

          const productCart: ProductCart = {
            id: childSnapshot.key ?? '',
            uid: data['uid'],
            amount: data['amount'],
            code: data['code'],
            name: data['name'],
            price: data['price'],
            bookType: data['bookType'] === 'dura' ? BookType.hardCover : BookType.softCover,
            bookAmount: data['bookAmount'],
            photoUrl: data['photoUrl'],
            student,
            bookModel,
          };

          products.push(productCart);
        });

        const lastUpdateDate = JSON.parse(cartData?.['lastUpdateDate'] ?? JSON.stringify(new Date()));

        const cart: Cart = {
          uid,
          lastUpdateDate,
          products,
        };

        callback(cart);
      } catch (error) {
        if (errorCallback) {
          errorCallback(error as Error);
        }
      }
    },
    errorCallback
  );

  return unsubscribe;
};

interface ProductCartRegistration {
  [url: string]:
    | string
    | number
    | boolean
    | null
    | {
        [key: string]: string | number | boolean | null;
      };
}

export const addProductToCart = async (uid: string, productCart: ProductCart, lastUpdateDate: Date): Promise<void> => {
  if (!uid) throw new Error('invalid-uid');
  if (!productCart) throw new Error('invalid-product-cart-data');
  if (!productCart.amount || productCart.amount < 0) throw new Error('invalid-product-amount');
  if (!productCart.student) throw new Error('invalid-student-data');

  const productCartKey = `${productCart.code}_${productCart.student.id}`;

  const registerNewProductCartUpdate: ProductCartRegistration = {
    [`${BASE_DATABASE_URL}/carts/${uid}/uid`]: uid,
    [`${BASE_DATABASE_URL}/carts/${uid}/lastUpdateDate`]: JSON.stringify(lastUpdateDate),
  };

  const productCartRef: DatabaseReference = ref(
    database,
    `${BASE_DATABASE_URL}/carts/${uid}/products/${productCartKey}`
  );

  const productCartSnap: DataSnapshot = await get(productCartRef);

  // if the product isn't in database, create a new register
  if (!productCartSnap.exists()) {
    let bookModel: string = 'web';

    if (productCart.bookModel === BookModel.collective) {
      bookModel = 'coletivo';
    } else if (productCart.bookModel === BookModel.draw) {
      bookModel = 'desenho';
    } else {
      bookModel = 'web';
    }

    registerNewProductCartUpdate[`${BASE_DATABASE_URL}/carts/${uid}/products/${productCartKey}`] = {
      id: productCartKey,
      uid,
      amount: productCart.amount,
      // product data
      code: productCart.code,
      name: productCart.name,
      price: productCart.price,
      bookType: productCart.bookType === BookType.hardCover ? 'dura' : 'mole',
      bookAmount: productCart.bookAmount,
      bookModel,
      photoUrl: productCart.photoUrl,
      // student data
      studentId: productCart.student.id,
      schoolCode: productCart.student.schoolCode,
      schoolName: productCart.student.schoolName,
      studentCode: productCart.student.studentCode,
      studentClass: productCart.student.studentClass,
      studentName: productCart.student.studentName,
      studentFullname: productCart.student.studentFullname,
      biography: productCart.student.biography,
      studentPhotoUrl: productCart.student.photoUrl,
      imagePermission: productCart.student.imagePermission,
      disableBiography: productCart.student.disableBiography,
    };
  } else {
    // otherwise, just increase the amount in cart
    const data = productCartSnap.val();
    const currentAmount: number = data['amount'];

    registerNewProductCartUpdate[`${BASE_DATABASE_URL}/carts/${uid}/products/${productCartKey}/amount`] =
      currentAmount + productCart.amount;
  }

  return update(ref(database), registerNewProductCartUpdate);
};

export const updateAmountProductCart = async (
  uid: string,
  productCode: string,
  studentId: string,
  lastUpdateDate: Date,
  newAmount: number
): Promise<void> => {
  if (!uid) throw new Error('invalid-uid');
  if (!productCode) throw new Error('invalid-product-code');
  if (!studentId) throw new Error('invalid-student-data');
  if (newAmount < 0) throw new Error('invalid-product-amount');

  const productCartKey = `${productCode}_${studentId}`;

  const registerNewAmountProductCartUpdate: ProductCartRegistration = {
    [`${BASE_DATABASE_URL}/carts/${uid}/lastUpdateDate`]: JSON.stringify(lastUpdateDate),
    [`${BASE_DATABASE_URL}/carts/${uid}/products/${productCartKey}/amount`]: newAmount || 0,
  };

  return update(ref(database), registerNewAmountProductCartUpdate);
};

export const deleteProductCart = async (
  uid: string,
  productCode: string,
  studentId: string,
  lastUpdateDate: Date
): Promise<void> => {
  if (!uid) throw new Error('invalid-uid');
  if (!productCode) throw new Error('invalid-product-code');
  if (!studentId) throw new Error('invalid-student-data');

  const productCartKey = `${productCode}_${studentId}`;

  const deleteProductCartUpdate: ProductCartRegistration = {
    [`${BASE_DATABASE_URL}/carts/${uid}/lastUpdateDate`]: JSON.stringify(lastUpdateDate),
    [`${BASE_DATABASE_URL}/carts/${uid}/products/${productCartKey}`]: null,
  };

  return update(ref(database), deleteProductCartUpdate);
};
