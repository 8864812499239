import { getApps, initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyAeJ0t_cg4cEIFRxb8HoXvHR0egQH7NPVM',
  authDomain: 'startupweekend-175921.firebaseapp.com',
  databaseURL: 'https://startupweekend-175921.firebaseio.com',
  // databaseURL: "https://startupweekend-175921.firebaseio.com/",
  projectId: 'startupweekend-175921',
  storageBucket: 'startupweekend-175921.appspot.com',
  messagingSenderId: '650196234622',
  appId: '1:650196234622:web:6154017b65c5349c06c32f',
  measurementId: 'G-X4DGRVTX61',
};

export const initFirebase = () => {
  if (!getApps().length) {
    return initializeApp(firebaseConfig);
  }

  return getApps()[0];
};
