import { Container, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Student } from 'types';

import './styles.scss';

interface StudentItemProps {
  student: Student;
  selectedStudentId: string | null;
  incompleteRegistration?: boolean;
  hideEditButton?: boolean;
  onSelectStudent: (studentId: string) => void;
  onEditClick?: (student: Student) => void;
  onDeleteClick?: (student: Student) => void;
}

export const StudentItem = ({
  student,
  selectedStudentId,
  incompleteRegistration = false,
  hideEditButton = false,
  onSelectStudent,
  onEditClick,
  onDeleteClick,
}: StudentItemProps) => {
  return (
    <Container
      fluid
      className="d-flex flex-row justify-content-between align-items-center fw-lighter w-100 m-0 py-3 student-item"
    >
      <div className="d-flex flex-1 flex-row justify-content-between align-items-center w-100">
        <div className="d-flex flex-4 align-items-center">
          <Form.Check
            id={student?.id}
            value={student?.id}
            type="radio"
            className="cursor-pointer"
            label={
              <span
                className={classNames(
                  'text-wrap me-5',
                  { 'fw-bolder': selectedStudentId === student?.id },
                  { 'text-danger': incompleteRegistration }
                )}
              >
                {student?.studentName} | {student?.schoolName}
              </span>
            }
            onChange={() => onSelectStudent(student?.id)}
            checked={selectedStudentId === student?.id}
          />
        </div>
        <div className="d-flex flex-1 align-items-center">
          {!hideEditButton && (
            <button
              onClick={() => onEditClick?.(student)}
              className="icon-button border-0 bg-transparent me-5 text-primary"
              data-tooltip="Editar cadastro do aluno"
            >
              <FontAwesomeIcon icon={faEdit} className="edit-student-button" />
            </button>
          )}

          <button
            data-tooltip="Excluir cadastro do aluno"
            onClick={() => onDeleteClick?.(student)}
            className="icon-button border-0 bg-transparent text-danger"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
    </Container>
  );
};
