import { getDatabase, update, ref, DatabaseReference, onValue, DataSnapshot } from 'firebase/database';
import { AccountInfo } from 'types';
import { initFirebase } from 'services/firebase/initFirebase';
import { BASE_DATABASE_URL } from '..';

initFirebase();
const database = getDatabase();

export const registerUser = async (accountInfo: AccountInfo): Promise<void> => {
  if (!accountInfo?.uid) throw new Error('invalid-uid');

  const updateAccountInfo = {
    [`${BASE_DATABASE_URL}/users/${accountInfo.uid}/`]: accountInfo,
  };

  return update(ref(database), updateAccountInfo);
};

export const listenAccountInfo = (
  uid: string,
  callback: (accountInfo: AccountInfo) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => {
  if (!uid?.trim?.()) {
    if (errorCallback) {
      errorCallback(new Error('Invalid uid'));
    }

    return () => null;
  }

  const userRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/users/${uid}/`);

  const unsubscribe = onValue(
    userRef,
    (snapshot: DataSnapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();

        const accountInfo: AccountInfo = {
          uid: snapshot.key ?? '',
          email: data['email'] ?? '',
          name: data['name'] ?? '',
          phone: data['phone'] ?? '',
          customerProviderId: data['customerProviderId'],
          customerName: data['customerName'],
          customerEmail: data['customerEmail'],
          customerCpf: data['customerCpf'],
          customerPhone: data['customerPhone'],
          customerCep: data['customerCep'],
          customerAdressNumber: data['customerAdressNumber'],
        };

        callback(accountInfo);
      } else {
        if (errorCallback) {
          errorCallback(new Error('User data not found'));
        }
      }
    },
    errorCallback
  );

  return unsubscribe;
};
