import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { IMaskInput } from 'react-imask';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Cards, { Focused } from 'react-credit-cards';

import { toCurrency, validateExpiration } from 'utils';
import { useAppDispatch } from 'store/hooks';
import { setLastPaymentDone } from 'features/shoppingSlice';
import { functionsService } from 'services/functions';

import 'react-credit-cards/lib/styles.scss';
import './styles.scss';

interface CreditCardPaymentProps {
  totalCartPrice: number;
  discountPercentage: number;
  countBooks: number;
}

const CreditCardPayment = ({ totalCartPrice, discountPercentage, countBooks }: CreditCardPaymentProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [loadingPaymentServer, setLoadingPaymentServer] = useState(false);

  const validationSchema = object().shape({
    cardNumber: string()
      .label('cardNumber')
      .required('O número do cartão é de preenchimento obrigatório')
      .matches(/^(0|[1-9]\d*)(\.\d+)?$/, 'Este campo só aceita valores numéricos')
      .length(16, 'O número do cartão deve ser composto por 16 dígitos'),
    holderName: string().label('holderName').required('O nome é de preenchimento obrigatório'),
    expiration: string()
      .label('expiration')
      .required('A validade é de preenchimento obrigatório')
      .length(4, 'A validade deve ser informada com 4 dígitos')
      .test('validate_expiration', 'A data de validade do cartão é inválida', validateExpiration),
    ccv: string()
      .label('ccv')
      .required('O código de segurança é de preenchimento obrigatório')
      .length(3, 'O código de segurança precisa ter 3 dígitos'),
    installmentCount: string().label('installmentCount'),
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cardNumber: '',
      holderName: '',
      expiration: '',
      ccv: '',
      installmentCount: 1,
      error_server: null,
    },
  });

  const handleSubmitPayment = useCallback(
    async ({ cardNumber, holderName, expiration, ccv, installmentCount }: FieldValues) => {
      clearErrors();
      setLoadingPaymentServer(true);

      try {
        const expiryMonth = (expiration as string).substring(0, 2);
        const expiryYear = `20${(expiration as string).substring(2, 4)}`;

        const paymentResult = await functionsService.paymentCreditCard(
          totalCartPrice,
          `Compra de livros ImaginaKIDS [${countBooks} livro(s)]`,
          discountPercentage,
          {
            number: cardNumber,
            expiryMonth,
            expiryYear,
            ccv,
            holderName,
          },
          installmentCount
        );

        setLoadingPaymentServer(false);

        dispatch(setLastPaymentDone(paymentResult.paymentId));

        navigate(`/payment-confirmation/${paymentResult.paymentId}`);
      } catch (error: any) {
        clearErrors();
        setLoadingPaymentServer(false);

        const { details } = JSON.parse(JSON.stringify(error));

        if (details?.errors?.[0].description) {
          setError('error_server', { type: 'server', message: details?.errors?.[0].description });
        } else {
          setError('error_server', { type: 'server', message: error?.message });
        }

        document.body.scrollTop = document.body.scrollHeight;
      }
    },
    [totalCartPrice, discountPercentage, countBooks, clearErrors, setError, dispatch, navigate]
  );

  const errorMessage = useMemo(() => {
    if (errors.error_server) {
      const message = errors.error_server?.message ?? '';

      return (
        <Form.Text className="py-2 px-0 fw-bolder error-message">
          <FontAwesomeIcon icon={faCircleExclamation} /> {message}
        </Form.Text>
      );
    }

    return null;
  }, [errors.error_server]);

  const INTEREST_RATE = 0.02;

  const valueWithInterest = useCallback(
    (value: number, installmentCount: number) => value * Math.pow(1.0 + INTEREST_RATE, installmentCount),
    []
  );

  const installmentOptions = useMemo(() => {
    const discount = discountPercentage / 100.0;

    const installmentValues = Array.from(Array(10).keys()).map((n) => {
      // installment number
      const num = n + 1;

      if (num >= 1 && num <= 4) {
        return {
          number: num,
          value: (totalCartPrice * (1 - discount)) / (num * 1.0),
          interest: false,
        };
      } else {
        return {
          number: num,
          value: valueWithInterest(totalCartPrice * (1 - discount), num) / (num * 1.0),
          interest: true,
        };
      }
    });

    let maxInstallmentIndex = 0;

    for (let i = 0; i < installmentValues.length; i++) {
      if (installmentValues[i].value < 5) {
        break;
      }

      maxInstallmentIndex = i;
    }

    // remove values less than 5 reais
    if (maxInstallmentIndex < installmentValues.length) {
      installmentValues.splice(maxInstallmentIndex + 1);
    }

    return installmentValues.map((opt) => (
      <option key={`installment-${opt.number}`} value={opt.number}>
        Crédito em {opt.number}x de {toCurrency(opt.value)} {opt.interest ? '(com juros)' : '(sem juros)'}
      </option>
    ));
  }, [totalCartPrice, discountPercentage, valueWithInterest]);

  const [focusedField, setFocusedField] = useState('');

  return (
    <Row className="align-items-center">
      <Col xs={12} lg={6}>
        <Form onSubmit={handleSubmit(handleSubmitPayment)}>
          <Form.Group className="mb-2 required" controlId="formCardNumber">
            <Form.Label className="control-label">Número do cartão</Form.Label>
            <Form.Control
              {...register('cardNumber')}
              as={IMaskInput}
              mask="0000 0000 0000 0000"
              type="text"
              placeholder="0000 0000 0000 0000"
              unmask={true}
              onAccept={(value: unknown) => {
                clearErrors();
                setValue('cardNumber', value as string);
              }}
              onFocus={() => setFocusedField('number')}
            />
            <div className="field-error-text">
              {errors.cardNumber && (
                <Form.Text className="text-danger fs-7">{errors.cardNumber.message ?? ''}</Form.Text>
              )}
            </div>
          </Form.Group>

          <Form.Group className="mb-2 required" controlId="formHolderName">
            <Form.Label className="control-label">Nome do titular (como está escrito no cartão)</Form.Label>
            <Form.Control
              {...register('holderName')}
              onChange={(evt) => {
                clearErrors();
                setValue('holderName', evt.target.value);
              }}
              type="text"
              placeholder="Nome do titular"
              onFocus={() => setFocusedField('name')}
            />
            <div className="field-error-text">
              {errors.holderName && (
                <Form.Text className="text-danger fs-7">{errors.holderName.message ?? ''}</Form.Text>
              )}
            </div>
          </Form.Group>

          <Form.Group className="mb-2 required" controlId="formExpiration">
            <Form.Label className="control-label">Validade</Form.Label>

            <div className="col-6 col-md-2">
              <Form.Control
                {...register('expiration')}
                type="text"
                as={IMaskInput}
                mask="00/00"
                placeholder="00/00"
                unmask={true}
                onAccept={(value: unknown) => {
                  clearErrors();
                  setValue('expiration', value as string);
                }}
                onFocus={() => setFocusedField('expiry')}
              />
            </div>
            <div className="field-error-text">
              {errors.expiration && (
                <Form.Text className="text-danger fs-7">{errors.expiration.message ?? ''}</Form.Text>
              )}
            </div>
          </Form.Group>

          <Form.Group className="mb-2 required" controlId="formCcv">
            <Form.Label className="control-label">Código de segurança (3 dígitos no fundo do cartão)</Form.Label>
            <div className="col-6 col-md-2">
              <Form.Control
                {...register('ccv')}
                type="text"
                placeholder="CCV"
                onChange={(evt) => {
                  clearErrors();
                  setValue('ccv', evt.target.value);
                }}
                onFocus={() => setFocusedField('cvc')}
                maxLength={3}
              />
            </div>
            <div className="field-error-text">
              {errors.ccv && <Form.Text className="text-danger fs-7">{errors.ccv.message ?? ''}</Form.Text>}
            </div>
          </Form.Group>

          <Form.Group className="mb-2 required" controlId="formInstallmentCount">
            <Form.Label className="control-label">Número de parcelas</Form.Label>
            <Form.Select {...register('installmentCount')} className="payment-installment-select">
              {installmentOptions}
            </Form.Select>
            <div className="field-error-text" />
          </Form.Group>

          <Button variant="primary" className="mt-4 text-white" type="submit">
            Fechar pedido
          </Button>
        </Form>
        <Container
          className={classNames('m-0 p-4 mt-5 mb-8', {
            'error-container': !!errorMessage,
          })}
        >
          {errorMessage}
        </Container>
        {loadingPaymentServer && (
          <div className="loading-server">
            <div className="position-absolute top-50 start-50 translate-middle">
              <div>
                <div className="payment-loader">
                  <div className="pad">
                    <div className="chip"></div>
                    <div className="line line1"></div>
                    <div className="line line2"></div>
                  </div>
                </div>
              </div>
              <div className="bg-white p-4 m-0 rounded-4 border border-primary border-1 text-center">
                <p className="p-0 m-0 fw-bolder">Processando seu pagamento.</p>
                <p className="p-0 m-0">Não recarregue ou feche essa página enquanto esta operação não for concluída.</p>
              </div>
            </div>
          </div>
        )}
      </Col>
      <Col className="d-none d-lg-block text-center">
        <Cards
          cvc={getValues().ccv ?? ''}
          expiry={getValues().expiration ?? ''}
          focused={focusedField as Focused}
          name={getValues().holderName ?? ''}
          number={getValues().cardNumber ?? ''}
          placeholders={{
            name: 'SEU NOME AQUI',
          }}
        />
      </Col>
    </Row>
  );
};

export default CreditCardPayment;
