import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
import { User, AccountInfo } from "types";

export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
  accountInfo: AccountInfo | null;
  isLoading: boolean;
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    user: null,
    accountInfo: null,
    isLoading: true,
  } as AuthState,
  reducers: {
    clearAuthenticatedUser: (state) => {
      state.user = null;
      state.accountInfo = null;
      state.isAuthenticated = false;
    },
    setAuthenticatedUser: (state, { payload: user }: PayloadAction<User | null>) => {
      state.user = user;
      state.isAuthenticated = true;
    },
    setAccountInfo: (state, { payload: accountInfo }: PayloadAction<AccountInfo | null>) => {
      state.accountInfo = accountInfo;
    },
    setIsLoading: (state, { payload: isLoading }: PayloadAction<boolean>) => {
      state.isLoading = isLoading;
    },
  },
});

export const { clearAuthenticatedUser, setAuthenticatedUser, setAccountInfo, setIsLoading } = authSlice.actions;

export default authSlice.reducer;

export const selectAuthUser = (state: RootState) => state.auth.user;
export const selectAccountInfo = (state: RootState) => state.auth.accountInfo;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectIsLoading = (state: RootState) => state.auth.isLoading;
