import { useEffect, useState } from 'react';
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from 'react-joyride';

import guidoSuccess from 'assets/images/guido_success.svg';
import guidoselectStudent from 'assets/images/guido_select_student.svg';

interface OnboardingProps {
  start?: boolean;
}

export const Onboarding = ({ start = false }: OnboardingProps) => {
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    if (start) {
      if (!localStorage.getItem('imaginaTour')) {
        setRun(true);
      }
    }
  }, [start]);

  const onboardingSteps: Array<Step> = [
    {
      target: 'body',
      placement: 'center',
      content: (
        <div className="p-0 m-0">
          <h4 className="fw-bolder mb-8">Seja bem vindo(a) ao ImaginaShopping!</h4>
          <p>Aqui você poderá adquirir livros dos seus autores favoritos!</p>
          <img src={guidoSuccess} alt="Pinguim festejando" style={{ maxHeight: '7rem' }} />
        </div>
      ),
    },
    {
      target: '.add-student-button',
      content: (
        <>
          <p className="fw-bolder">Este botão te permite adicionar novos alunos à sua conta.</p>
          <p>
            Para adquirir o livro de um aluno é necessário que ele seja adicionado aqui com o código fornecido pela
            escola. Você precisará fornecer uma <span className="fw-bolder">biografia</span> e uma{' '}
            <span className="fw-bolder">foto</span> do aluno para serem adicionados aos livros.
          </p>
        </>
      ),
      placement: 'left-start',
    },
    {
      target: '.card-students',
      content: (
        <>
          <p className="fw-bolder">Nesta área são listados os alunos que você adicionar à sua conta.</p>
          <p>
            Se estiverem faltando informações a serem adicionadas ao aluno, o nome dele aparecerá em vermelho. Você terá
            ainda acesso às opções de editar e excluir alunos.
          </p>
        </>
      ),
      placement: 'right',
    },
    {
      target: '.order-history-item',
      content: <p className="fw-bolder">Este botão te permite listar os últimos pedidos realizados.</p>,
      placement: 'bottom-end',
    },
    {
      target: '.cart-item',
      content: <p className="fw-bolder">Este botão te permite abrir o seu carrinho e finalizar sua compra.</p>,
      placement: 'bottom-end',
    },
    {
      target: '.signout-item',
      content: (
        <p className="fw-bolder">
          Por fim, quando finalizar suas compras, você pode deixar o sistema usando esse botão.
        </p>
      ),
      placement: 'bottom-end',
    },
    {
      target: 'body',
      content: (
        <>
          <p className="fw-bolder">Mais uma vez, seja bem vindo(a) e boas ImaginaCompras! :D</p>
          <img src={guidoselectStudent} alt="Pinguim apontando para cima" style={{ maxHeight: '7rem' }} />
        </>
      ),
      placement: 'center',
    },
  ];

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data;

    if (
      ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status) ||
      ([ACTIONS.CLOSE] as string[]).includes(action)
    ) {
      localStorage.setItem('imaginaTour', 'true');

      setRun(false);
      setStepIndex(0);
    } else if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      setTimeout(() => {
        setStepIndex(nextStepIndex);
      }, 400);
    }
  };

  return (
    <Joyride
      run={run}
      steps={onboardingSteps}
      stepIndex={stepIndex}
      callback={handleJoyrideCallback}
      continuous={true}
      hideBackButton={true}
      disableScrolling
      showProgress
      locale={{
        back: 'Voltar',
        close: 'Fechar',
        last: 'Finalizar',
        next: 'Próximo',
        open: 'Abra o diálogo',
        skip: 'Pular',
      }}
      styles={{
        options: {
          zIndex: 35,
        },
      }}
    />
  );
};
