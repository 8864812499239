import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Container, Button } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { object, ref, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCircleExclamation, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { IMaskInput } from 'react-imask';

import { AppLoader } from 'components';
import { useAppDispatch } from 'store/hooks';
import { ROUTES } from 'constants/';
import { setAuthenticatedUser } from 'features/authSlice';
import { authService } from 'services/auth';
import { databaseService } from 'services/database';

import logo from '../../../logo.svg';

import './styles.scss';

const CreateAccount = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loadingLogin, setLoadingLogin] = useState(false);

  const validationSchema = object().shape({
    name: string()
      .label('name')
      .required('O nome completo é obrigatório')
      .min(5, 'O nome deve ter pelo menos 5 caracteres')
      .matches(/[a-zA-Z]+\s+[a-zA-Z]+/g, 'É obrigatório informar pelo menos um sobrenome'),
    phone: string()
      .label('phone')
      .required('O celular é obrigatório')
      .min(11, 'O número de celular precisa ter 11 dígitos (DDD + número)'),
    email: string().label('email').required('O email é obrigatório').email('Digite um formato de email válido'),
    password: string()
      .label('password')
      .required('A senha é de preenchimento obrigatório')
      .min(6, 'A senha deve conter pelo menos 6 caracteres'),
    confirmPassword: string()
      .label('confirmPassword')
      .required('A confirmação de senha é obrigatória')
      .oneOf([ref('password')], 'As senhas não são iguais. Tente novamente'),
  });
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
      error_server: null,
    },
  });

  const handleSubmitCreateAccount = useCallback(
    ({ name, phone, email, password }: FieldValues) => {
      setLoadingLogin(true);

      authService
        .createAuthUser(email, password)
        .then((authUser) => {
          databaseService
            .registerUser(authUser, name, phone)
            .then((user) => {
              dispatch(setAuthenticatedUser(user));
              setLoadingLogin(false);
              navigate(ROUTES.HOME);
            })
            .catch((error) => {
              setLoadingLogin(false);
              console.log(error);

              setError('error_server', { type: 'server', message: 'Erro registrando novo usuário' });
            });
        })
        .catch((error: any) => {
          setLoadingLogin(false);
          console.log(error);

          if (error.code === 'auth/email-already-in-use') {
            setError('error_server', { type: 'server', message: 'Este email já está cadastrado' });
          } else if (error.code === 'auth/invalid-email') {
            setError('error_server', { type: 'server', message: 'Email inválido' });
          } else if (error.code === 'auth/invalid-password') {
            setError('error_server', { type: 'server', message: 'Senha inválida' });
          } else {
            setError('error_server', { type: 'server', message: 'Erro registrando novo usuário' });
          }
        });
    },
    [setError, dispatch, navigate]
  );

  return (
    <div className="py-6 d-flex flex-column justify-content-center create-account">
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Form method="POST" onSubmit={handleSubmit(handleSubmitCreateAccount)}>
            <div className="text-center mb-3">
              <img alt="" src={logo} height="48" className="d-inline-block align-top" />
            </div>
            <h4 className="text-center fw-bold">ImaginaShopping</h4>
            <p className="text-center">Cadastre seu usuário e senha para utilizar o sistema.</p>
            <div className="fieldset mb-3">
              <Form.Label htmlFor="name">Nome completo</Form.Label>
              <Form.Control
                {...register('name')}
                type="text"
                id="name"
                name="name"
                placeholder="Nome completo"
                onChange={(evt) => {
                  clearErrors();
                  setValue('name', evt.target.value);
                }}
              />
              {errors.name && <Form.Text className="text-danger field-error">{errors.name?.message}</Form.Text>}
            </div>
            <div className="fieldset mb-3">
              <Form.Label htmlFor="phone">Celular</Form.Label>
              <Form.Control
                {...register('phone')}
                as={IMaskInput}
                mask="(00)00000-0000"
                type="phone"
                placeholder="(00)00000-0000"
                id="phone"
                name="phone"
                unmask={true}
                onAccept={(value: unknown) => {
                  clearErrors();
                  setValue('phone', value as string);
                }}
              />
              {errors.phone && <Form.Text className="text-danger field-error">{errors.phone?.message}</Form.Text>}
            </div>
            <div className="fieldset mb-3">
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.Control
                {...register('email')}
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                onChange={(evt) => {
                  clearErrors();
                  setValue('email', evt.target.value);
                }}
              />
              {errors.email && <Form.Text className="text-danger field-error">{errors.email?.message}</Form.Text>}
            </div>
            <div className="fieldset mb-3">
              <Form.Label htmlFor="password">Senha</Form.Label>
              <div className="p-0 m-0 position-relative">
                <Form.Control
                  {...register('password')}
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder="Senha"
                  onChange={(evt) => {
                    clearErrors();
                    setValue('password', evt.target.value);
                  }}
                ></Form.Control>
                <FontAwesomeIcon
                  role="button"
                  onClick={() => setShowPassword((val) => !val)}
                  icon={!showPassword ? faEye : faEyeSlash}
                  className="show-password"
                />
              </div>
              {errors.password && <Form.Text className="text-danger field-error">{errors.password?.message}</Form.Text>}
            </div>
            <div className="fieldset mb-6">
              <Form.Label htmlFor="confirmPassword">Confirmação de senha</Form.Label>
              <div className="p-0 m-0 position-relative">
                <Form.Control
                  {...register('confirmPassword')}
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  name="confirmPassword"
                  placeholder="Confirmação de senha"
                  onChange={(evt) => {
                    clearErrors();
                    setValue('confirmPassword', evt.target.value);
                  }}
                ></Form.Control>
                <FontAwesomeIcon
                  role="button"
                  onClick={() => setShowConfirmPassword((val) => !val)}
                  icon={!showConfirmPassword ? faEye : faEyeSlash}
                  className="show-password"
                />
              </div>
              {errors.confirmPassword && (
                <Form.Text className="text-danger field-error">{errors.confirmPassword?.message}</Form.Text>
              )}
            </div>
            {errors.error_server && (
              <Container className="m-0 p-3 mb-4 text-center field-error error-container">
                <Form.Text className="py-1 px-0 fw-bolder error-message">
                  <FontAwesomeIcon icon={faCircleExclamation} /> {errors.error_server?.message}
                </Form.Text>
              </Container>
            )}
            <div className="d-grid mb-3">
              <Button type="submit" className="text-white">
                Cadastrar
              </Button>
            </div>
            <div className="text-center text-black forgot-password">
              Já tem uma conta? <Link to={ROUTES.LOGIN}>Faça o login aqui</Link>
            </div>
            {loadingLogin && (
              <div className="loading-create-account">
                <div className="position-absolute top-50 start-50 translate-middle">
                  <AppLoader />
                </div>
              </div>
            )}
          </Form>
          <div className="mt-6 text-center text-black forgot-password">
            <a href="https://www.imaginakids.com.br/">
              <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o site
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
