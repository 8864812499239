import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "features/authSlice";
import shoppingReducer from "features/shoppingSlice";
import studentRegistrationReducer from "features/studentRegistrationSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    shopping: shoppingReducer,
    studentRegistration: studentRegistrationReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
