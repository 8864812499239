import { useCallback, useEffect, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import { Button, Container, Form } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { faArrowLeft, faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ROUTES } from 'constants/';
import { AppLoader } from 'components';
import { useAuth } from 'hooks';
import { setAuthenticatedUser, setIsLoading } from 'features/authSlice';
import { authService } from 'services/auth';
import { useAppDispatch } from 'store/hooks';

import logo from '../../../logo.svg';

import './styles.scss';

const ForgetPasswordPage = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { isAuthenticated, user, isLoading } = useAuth();

  const [loadingLogin, setLoadingLogin] = useState(false);

  const validationSchema = object().shape({
    email: string().label('email').required('O email é obrigatório').email('Digite um formato de email válido'),
  });
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
      error_server: null,
      success_server: null,
    },
  });

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged(
      (user) => {
        dispatch(setAuthenticatedUser(user));
        setLoadingLogin(false);
        dispatch(setIsLoading(false));
      },
      (error) => {
        console.log(error);
        setError('error_server', { type: 'server', message: 'Erro realizando o login' });
      }
    );

    return unsubscribe;
  }, [setError, dispatch]);

  const handleSubmitForgetPassword = useCallback(
    async ({ email }: FieldValues) => {
      setLoadingLogin(true);
      try {
        await authService.forgetPassword(email);
        setError('success_server', { type: 'server', message: 'Email de recuperação enviado com sucesso!' });
      } catch (error: any) {
        console.log(error);

        setError('error_server', { type: 'server', message: 'Erro ao realizar a recuperação de senha' });
      } finally {
        setLoadingLogin(false);
      }
    },
    [setError]
  );

  // the auth state is loading...
  if (isLoading) {
    return (
      <div className="position-absolute top-50 start-50 translate-middle">
        <AppLoader />
      </div>
    );
  }

  // the user is authorized, navigate to the home
  if (isAuthenticated && user) {
    return <Navigate to={ROUTES.HOME} replace state={{ from: location }} />;
  }

  // otherwise, render login page
  return (
    <div className="py-6 d-flex flex-column justify-content-center login-form">
      <div className="auth-wrapper">
        <div className="auth-inner">
          <Form method="POST" onSubmit={handleSubmit(handleSubmitForgetPassword)}>
            <div className="text-center mb-3">
              <img alt="" src={logo} height="48" className="d-inline-block align-top" />
            </div>
            <h4 className="text-center fw-bold">ImaginaShopping</h4>
            <p className="text-center fw-bolder p-0 m-0">Esqueceu sua senha?</p>
            <p className="text-center p-0 m-0">
              Insira o email que você cadastrou para receber as instruções de recuperação
            </p>
            <div className="fieldset mb-3">
              <Form.Label htmlFor="email">Email</Form.Label>
              <Form.Control
                {...register('email')}
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                onChange={(evt) => {
                  clearErrors();
                  setValue('email', evt.target.value);
                }}
              />
              {errors.email && <Form.Text className="text-danger field-error">{errors.email?.message}</Form.Text>}
            </div>
            {errors.error_server && (
              <Container className="m-0 p-0 mb-4 text-center field-error">
                <Form.Text className="py-1 px-0 fw-bolder error-message">
                  <FontAwesomeIcon icon={faCircleExclamation} /> {errors.error_server?.message}
                </Form.Text>
              </Container>
            )}
            {errors.success_server && (
              <Container className="m-0 p-0 mb-4 text-center field-error">
                <Form.Text className="py-1 px-0 fw-bolder success-message">
                  <FontAwesomeIcon icon={faCircleCheck} /> {errors.success_server?.message}
                </Form.Text>
              </Container>
            )}
            <div className="d-grid mb-3">
              <Button type="submit" className="text-white">
                Enviar
              </Button>
            </div>
            <div className="text-center text-black forgot-password">
              Não possui mais esse email? <Link to={ROUTES.CREATE_ACCOUNT}>Crie uma nova conta</Link>
            </div>
            {loadingLogin && (
              <div className="loading-login">
                <div className="position-absolute top-50 start-50 translate-middle">
                  <AppLoader />
                </div>
              </div>
            )}
          </Form>
          <div className="mt-6 text-center text-black forgot-password">
            <a href="https://www.imaginakids.com.br/">
              <FontAwesomeIcon icon={faArrowLeft} /> Voltar para o site
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
