import axios from 'axios';
import { CepQueryResult } from 'services/functions';

export interface IRestApiService {
  getAddressByCep: (cep: string) => Promise<CepQueryResult>;
}

export const getAddressByCep = async (cep: string): Promise<CepQueryResult> => {
  const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  return response.data;
};

export const restApiService: IRestApiService = {
  getAddressByCep,
};
