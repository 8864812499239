import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from 'hooks';
import { ProductCart } from 'types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { composeUnsubscribers } from 'utils';

import { databaseService } from 'services/database';
import {
  CartState,
  selectSelectedStudentId,
  setCartState,
  setIsErrorCart,
  setIsLoadingCart,
  setSelectedStudentId,
  setStudentListData,
} from 'features/shoppingSlice';

const DataLoaderLayout = () => {
  const dispatch = useAppDispatch();

  const { user } = useAuth();
  const selectedStudentId = useAppSelector(selectSelectedStudentId);

  useEffect(() => {
    const unsubscribers: Array<() => void> = [];

    if (user?.uid) {
      unsubscribers.push(
        databaseService.listenStudentList(
          user.uid,
          (slist) => {
            // this causes an extra render every time the listener is invoked with a null selectedStudentId
            // TODO: refactor to avoid this
            if (!selectedStudentId && slist?.length > 0) {
              dispatch(setSelectedStudentId(slist[0]?.id));
            }

            dispatch(
              setStudentListData({
                data: slist,
                isError: false,
                isLoading: false,
              })
            );
          },
          (error) => {
            console.log(error);

            dispatch(
              setStudentListData({
                data: [],
                isError: true,
                isLoading: false,
              })
            );
          }
        )
      );

      unsubscribers.push(
        databaseService.listenProductCart(
          user.uid,
          (cart) => {
            const products: Array<ProductCart> = cart.products;

            const cartState: CartState = {
              uid: cart.uid,
              lastUpdateDate: cart.lastUpdateDate,
              products,
            };

            dispatch(setCartState(cartState));
            dispatch(setIsLoadingCart(false));
            dispatch(setIsErrorCart(false));
          },
          (error) => {
            console.log(error);
            dispatch(setIsLoadingCart(false));
            dispatch(setIsErrorCart(true));
          }
        )
      );
    }

    return composeUnsubscribers(unsubscribers);
  }, [user?.uid, selectedStudentId, dispatch]);

  return <Outlet />;
};

export default DataLoaderLayout;
