import { Outlet } from 'react-router-dom';

import './styles.scss';

const FullLayout = () => {
  return (
    <div className="full-layout">
      <main className="h-100">
        <Outlet />
      </main>
    </div>
  );
};

export default FullLayout;
