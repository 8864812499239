import { useEffect } from 'react';
import { Tabs, Tab, Col, Container, Row } from 'react-bootstrap';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch } from 'store/hooks';
import { setLastPaymentDone } from 'features/shoppingSlice';
import CreditCardPayment from './CreditCardPayment';
import PixPayment from './PixPayment';

import PixLogo from 'assets/icons/PixLogo';

import './styles.scss';

interface PaymentMethodProps {
  totalCartPrice: number;
  discountPercentage: number;
  countBooks: number;
}

export const PaymentMethod = ({ totalCartPrice, discountPercentage, countBooks }: PaymentMethodProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setLastPaymentDone(''));
  }, [dispatch]);

  return (
    <Container className="payment-method-container">
      <Row>
        <Col className="p-0 m-0 my-2">
          <Tabs defaultActiveKey="cartao-credito" id="tab-payment_method" className="nav-fill nav-pills mb-3">
            <Tab
              eventKey="cartao-credito"
              title={
                <span className="d-flex flex-row justify-content-center align-items-center gap-2">
                  <FontAwesomeIcon icon={faCreditCard} /> Cartão de Crédito
                </span>
              }
            >
              <Container>
                <CreditCardPayment
                  totalCartPrice={totalCartPrice}
                  discountPercentage={discountPercentage}
                  countBooks={countBooks}
                />
              </Container>
            </Tab>
            <Tab
              eventKey="pix"
              title={
                <span className="d-flex flex-row justify-content-center align-items-center gap-2">
                  <PixLogo className="pix-logo" /> Pix
                </span>
              }
            >
              <Container>
                <PixPayment
                  totalCartPrice={totalCartPrice}
                  discountPercentage={discountPercentage}
                  countBooks={countBooks}
                />
              </Container>
            </Tab>
          </Tabs>
        </Col>
      </Row>
    </Container>
  );
};
