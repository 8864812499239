import { AccountInfo, Cart, Order, Product, ProductCart, Student } from 'types';
import { registerUser, listenAccountInfo } from './impl/user';
import { listenStudentList, registerStudentData, deleteStudentData } from './impl/student';
import { listenPaymentOrder, getDiscountValue, listenExistingOrders } from './impl/order';
import {
  getAvailableProductList,
  listenProductCart,
  addProductToCart,
  updateAmountProductCart,
  deleteProductCart,
} from './impl/product';

export const BASE_DATABASE_URL = 'shopping';

export interface IFirebaseDatabaseService {
  registerUser: (accountInfo: AccountInfo) => Promise<void>;
  registerStudentData: (uid: string, studentData: Student, updateStudentKey?: string) => Promise<void>;
  deleteStudentData: (uid: string, studentId: string) => Promise<void>;
  listenAccountInfo: (
    uid: string,
    callback: (accountInfo: AccountInfo) => unknown,
    errorCallback?: (error: Error) => unknown
  ) => () => void;
  listenStudentList: (
    uid: string,
    callback: (studentDataList: Array<Student>) => unknown,
    errorCallback?: (error: Error) => unknown
  ) => () => void;
  getAvailableProductList: (schoolCode: string) => Promise<Array<Product>>;
  listenProductCart: (
    uid: string,
    callback: (cart: Cart) => unknown,
    errorCallback?: (error: Error) => unknown
  ) => () => void;
  addProductToCart: (uid: string, productCart: ProductCart, lastUpdateDate: Date) => Promise<void>;
  updateAmountProductCart: (
    uid: string,
    productCode: string,
    studentId: string,
    lastUpdateDate: Date,
    newAmount: number
  ) => Promise<void>;
  deleteProductCart: (uid: string, productCode: string, studentId: string, lastUpdateDate: Date) => Promise<void>;
  listenPaymentOrder: (
    paymentId: string,
    callback: (order: Order | null) => unknown,
    errorCallback?: (error: Error) => unknown
  ) => () => void;
  getDiscountValue: (discountCode: string) => Promise<number>;
  listenExistingOrders: (
    uid: string,
    callback: (orders: Array<Order>) => unknown,
    errorCallback?: (error: Error) => unknown
  ) => () => void;
}

export const firebaseDatabaseService: IFirebaseDatabaseService = {
  registerUser,
  listenAccountInfo,
  listenStudentList,
  registerStudentData,
  deleteStudentData,
  getAvailableProductList,
  listenProductCart,
  addProductToCart,
  updateAmountProductCart,
  deleteProductCart,
  listenPaymentOrder,
  getDiscountValue,
  listenExistingOrders,
};
