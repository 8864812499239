import { User } from 'types';
import { firebaseAuthService } from 'services/firebase/auth';

export const createAuthUser = async (email: string, password: string): Promise<User> => {
  const credential = await firebaseAuthService.createAuthUser(email, password);

  const newUser: User = {
    uid: credential.user.uid,
    email: credential.user.email ?? '',
  };

  return newUser;
};

export const signInWithEmailAndPassword = async (email: string, password: string): Promise<User> => {
  const credential = await firebaseAuthService.signInWithEmailAndPassword(email, password);

  const newUser: User = {
    uid: credential.user.uid,
    email: credential.user.email ?? '',
  };

  return newUser;
};

export const signOut = async (): Promise<void> => firebaseAuthService.signOut();

export const onAuthStateChanged = (observer: (a: User | null) => void, error?: (a: Error) => void): (() => void) =>
  firebaseAuthService.onAuthStateChanged(observer, error);

export const forgetPassword = (email: string): Promise<void> => firebaseAuthService.forgetPassword(email);
