import { Outlet } from 'react-router-dom';

import './styles.scss';

const LoginLayout = () => {
  return (
    <div className="login-layout">
      <main className="w-100">
        <Outlet />
      </main>
    </div>
  );
};

export default LoginLayout;
