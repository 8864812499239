import { StorageError } from 'firebase/storage';
import { firebaseStorageService } from 'services/firebase/storage';

const createErrorObjectByCode = (error: StorageError): Error => {
  switch (error.code) {
    case 'storage/object-not-found':
      return new Error('Nenhum objeto na referência desejada');
    case 'storage/bucket-not-found':
      return new Error('Nenhum bucket configurado para o Cloud Storage');
    case 'storage/project-not-found':
      return new Error('Nenhum projeto configurado para o Cloud Storage');
    case 'storage/quota-exceeded':
      return new Error(
        'A cota do bucket do Cloud Storage foi excedida. Se você estiver no nível sem custo financeiro, faça upgrade para um plano pago. Se você estiver em um plano pago, entre em contato com o suporte do Firebase'
      );
    case 'storage/unauthenticated':
      return new Error('O usuário não está autenticado. Faça a autenticação e tente novamente');
    case 'storage/unauthorized':
      return new Error(
        'O usuário não está autorizado a executar a ação desejada. Verifique suas regras de segurança para garantir que estejam corretas'
      );
    case 'storage/retry-limit-exceeded':
      return new Error(
        'O limite máximo de tempo em uma operação (upload, download, exclusão etc.) foi excedido. Envie novamente'
      );
    case 'storage/invalid-checksum':
      return new Error(
        'O arquivo no cliente não corresponde à soma de verificação do arquivo recebido pelo servidor. Envie novamente'
      );
    case 'storage/canceled':
      return new Error('O usuário cancelou a operação');
    case 'storage/invalid-event-name':
      return new Error(
        'Nome inválido do evento fornecido. Precisa ser um dos valores [`running`, `progress`, `pause`]'
      );
    case 'storage/invalid-url':
      return new Error(
        'URL inválido fornecido para refFromURL(). Deve estar no formato: gs://bucket/object ou https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=&ltTOKEN>'
      );
    case 'storage/invalid-argument':
      return new Error(
        'O argumento transmitido a put() precisa ser matriz "File", "Blob" ou "UInt8". O argumento transmitido a putString() precisa ser uma string bruta "Base64" ou "Base64URL"'
      );
    case 'storage/no-default-bucket':
      return new Error('Nenhum bucket foi definido na propriedade storageBucket da configuração');
    case 'storage/cannot-slice-blob':
      return new Error(
        'Em geral, isso ocorre normalmente quando o arquivo local é alterado (excluído, salvo novamente etc.). Tente fazer o upload novamente após verificar que o arquivo não foi alterado'
      );
    case 'storage/server-file-wrong-size':
      return new Error(
        'O arquivo no cliente não corresponde ao tamanho do arquivo recebido pelo servidor. Envie novamente'
      );

    case 'storage/unknown':
      return new Error('Ocorreu um erro desconhecido.');
    default:
      return new Error('Falha na operação');
  }
};

export const uploadBytes = (
  remoteFilepath: string,
  data: Blob | File,
  onProgressCallback?: (progress: number) => void,
  onErrorCallback?: (error: Error) => void,
  onSuccessCallback?: (downloadUrl: string) => void
): void => {
  firebaseStorageService.uploadBytes(
    remoteFilepath,
    data,
    onProgressCallback,
    (error) => onErrorCallback?.(createErrorObjectByCode(error)),
    onSuccessCallback
  );
};
