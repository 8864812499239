import { Container } from 'react-bootstrap';

import guidoWaiting from 'assets/images/guido_waiting.svg';

import './styles.scss';

interface WaitingPaymentProps {
  paymentId: string;
}

export const WaitingPayment = ({ paymentId }: WaitingPaymentProps) => {
  return (
    <Container className="text-center py-2 px-15">
      <h3 className="fw-bold p-0 m-0">Aguardando confirmação do pagamento</h3>
      <img src={guidoWaiting} alt="Pinguim escutando música enquanto espera" className="p-0 m-0 waiting-image" />
      <div className="d-flex justify-content-center align-items-center py-4 mb-5 loader-container">
        <div className="box" id="box1"></div>
        <div className="box" id="box2"></div>
        <div className="box" id="box3"></div>
        <div className="box" id="box4"></div>
      </div>
      <h5 className="p-0 m-0 mb-2">
        Estamos aguardando o processamento do seu pedido com o código <span className="fw-bolder">{paymentId}</span>.
      </h5>
      <h5 className="p-0 m-0">
        <span className="fw-bolder">Dica:</span> use este número para se referir a este pedido nas comunicações com a
        nossa equipe.
      </h5>
    </Container>
  );
};
