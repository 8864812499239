import { useCallback, useMemo, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { faCheckCircle, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppLoader } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { databaseService } from 'services/database';
import { selectDiscountValue, setDiscountValue } from 'features/shoppingSlice';

export const PaymentDiscount = () => {
  const dispatch = useAppDispatch();

  const discountValue = useAppSelector(selectDiscountValue);

  const [discountCode, setDiscountCode] = useState('');

  const [discountLoading, setDiscountLoading] = useState(false);
  const [discountError, setDiscountError] = useState(false);

  const handleApplyButton = useCallback(async () => {
    try {
      setDiscountLoading(true);
      setDiscountError(false);
      dispatch(setDiscountValue(null));

      const discount = await databaseService.getDiscountValue(discountCode);

      dispatch(setDiscountValue(discount));

      setDiscountError(false);
      setDiscountLoading(false);
    } catch (error) {
      console.log(error);

      dispatch(setDiscountValue(null));
      setDiscountError(true);
      setDiscountLoading(false);
    }
  }, [discountCode, dispatch]);

  const discountResult = useMemo(() => {
    if (discountLoading) {
      return <AppLoader />;
    }

    if (discountError) {
      return (
        <Container className="m-0 p-4 error-container">
          <Form.Text className="py-2 px-0 fw-bolder error-message">
            <FontAwesomeIcon icon={faCircleExclamation} /> Ocorreu um erro processando este cupom
          </Form.Text>
        </Container>
      );
    }

    if (discountValue === null) {
      return null;
    }

    if (discountValue > 0) {
      return (
        <Container className="m-0 p-4 success-container">
          <Form.Text className="py-2 px-0 fw-bolder success-message">
            <FontAwesomeIcon icon={faCheckCircle} /> O cupom {discountCode?.trim().toUpperCase()} de {discountValue}%
            foi aplicado a esta compra!
          </Form.Text>
        </Container>
      );
    } else {
      return (
        <Container className="m-0 p-4 error-container">
          <Form.Text className="py-2 px-0 fw-bolder error-message">
            <FontAwesomeIcon icon={faCircleExclamation} /> O cupom {discountCode?.trim().toUpperCase()} não é válido
            para esta compra
          </Form.Text>
        </Container>
      );
    }
  }, [discountCode, discountError, discountLoading, discountValue]);

  return (
    <Container className="discount-container">
      <Row>
        <Col className="p-0 m-0 my-2">
          <Container className="m-0 p-0 mb-2 fw-bolder">Digite o código do seu cupom ou vale</Container>
          <Container className="m-0 p-0">
            <Row className="mb-3">
              <Col xs={8} md={5} className="my-2">
                <Form.Control
                  onChange={(evt) => {
                    setDiscountCode(evt.target.value);
                    setDiscountLoading(false);
                    setDiscountError(false);
                    dispatch(setDiscountValue(null));
                  }}
                  onKeyDown={(evt) => {
                    if (evt.key === 'Enter') {
                      handleApplyButton();
                    }
                  }}
                  type="text"
                  className="text-uppercase"
                />
              </Col>
              <Col xs={4} md={2} className="my-2">
                <Button className="bg-primary text-white" onClick={handleApplyButton}>
                  Aplicar
                </Button>
              </Col>
              <Col xs={12} md={5} className="my-2">
                {discountResult}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
