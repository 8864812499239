import { Crop } from 'react-image-crop';

export const getCroppedImageAsBase64 = (
  image: HTMLImageElement | null,
  crop: Crop | undefined
): Promise<string | null> => {
  if (!image || !crop) return new Promise((_, reject) => reject(new Error('Erro lendo dados da imagem')));

  const width = image.width;
  const height = image.height;

  const naturalWidth = image.naturalWidth;
  const naturalHeight = image.naturalHeight;

  // crop is using % unit
  const cropX = (width * crop.x) / 100.0;
  const cropY = (height * crop.y) / 100.0;
  const cropWidth = (width * crop.width) / 100.0;
  const cropHeight = (height * crop.height) / 100.0;

  const canvas = document.createElement('canvas');
  const scaleX = naturalWidth / width;
  const scaleY = naturalHeight / height;
  canvas.width = cropWidth * scaleX;
  canvas.height = cropHeight * scaleY;
  const ctx = canvas.getContext('2d');

  ctx?.drawImage(
    image,
    cropX * scaleX,
    cropY * scaleY,
    cropWidth * scaleX,
    cropHeight * scaleY,
    0,
    0,
    canvas.width,
    canvas.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) reject(new Error('Erro lendo dados da imagem'));
        else {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => resolve(reader.result as string | null);
        }
      },
      'image/jpeg',
      1
    );
  });
};

export const base64ToFile = (base64: string | null, filename: string): File | null => {
  if (!base64 || !filename) return null;

  let arr = base64.split(','),
    mime = arr[0].match(/:(.*?);/)?.[1] ?? arr[0],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const croppedImage = new File([u8arr], filename, { type: mime });
  return croppedImage;
};
