import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, Col, Container, Form } from 'react-bootstrap';
import { useWizard } from 'react-use-wizard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FieldValues, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { functionsService } from 'services/functions';
import { selectStudentRegistrationData, setStudentRegistrationData } from 'features/studentRegistrationSlice';
import { selectStudentListData } from 'features/shoppingSlice';
import { AppLoader } from 'components/AppLoader';

import './styles.scss';

export const RegisterStudent = () => {
  const dispatch = useAppDispatch();
  const { studentData } = useAppSelector(selectStudentRegistrationData);

  const studentListData = useAppSelector(selectStudentListData);

  const { nextStep } = useWizard();

  const validationSchema = object().shape({
    studentCode: string().label('studentCode').required('O campo código do aluno é de preenchimento obrigatório'),
  });
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      studentCode: studentData?.studentCode ?? '',
      error_loading_student_code: null,
      error_student_already_exists: null,
    },
  });

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(
      setStudentRegistrationData({ schoolCode: '', schoolName: '', studentData: null, disableBiography: false })
    );
  }, [dispatch]);

  const handleSubmitStudentCode = useCallback(
    async ({ studentCode }: FieldValues) => {
      if (
        studentListData.data?.find((std) => std.studentCode?.trim().toUpperCase() === studentCode?.trim().toUpperCase())
      ) {
        clearErrors();
        setError('error_student_already_exists', {
          type: 'server',
          message: 'Usuário já cadastrado! Informe outro código',
        });

        return;
      }

      setLoading(true);

      try {
        const { schoolCode, schoolName, studentData, disableBiography } = await functionsService.getStudentByCode(
          studentCode?.trim().toUpperCase()
        );

        dispatch(setStudentRegistrationData({ schoolCode, schoolName, studentData, disableBiography }));
        nextStep();
      } catch (error: any) {
        clearErrors();
        setError('error_loading_student_code', {
          type: 'server',
          message: 'Não foi possível encontrar os dados do aluno',
        });
        console.log(error?.message);
      }

      setLoading(false);
    },
    [dispatch, clearErrors, setError, nextStep, studentListData]
  );

  const errorMessage = useMemo(() => {
    if (!errors.studentCode && !errors.error_loading_student_code && !errors.error_student_already_exists) {
      return null;
    }

    let message = '';

    if (errors.studentCode) {
      message = errors.studentCode.message ?? '';
    } else if (errors.error_loading_student_code) {
      message = errors.error_loading_student_code?.message ?? '';
    } else if (errors.error_student_already_exists) {
      message = errors.error_student_already_exists?.message ?? '';
    }

    return (
      <Form.Text className="py-2 px-0 fw-bolder error-message">
        <FontAwesomeIcon icon={faCircleExclamation} /> {message}
      </Form.Text>
    );
  }, [errors.studentCode, errors.error_loading_student_code, errors.error_student_already_exists]);

  return (
    <Col lg={6}>
      <Container className="register-student">
        <h2>Digite o código do aluno:</h2>
        <Form onSubmit={handleSubmit(handleSubmitStudentCode)}>
          <Form.Group className="my-3" controlId="formStudentId">
            <Form.Control
              {...register('studentCode')}
              type="text"
              placeholder="Código do aluno"
              onChange={() => clearErrors()}
              className={classNames(
                'border border-2',
                {
                  'border-primary':
                    !errors.studentCode && !errors.error_loading_student_code && !errors.error_student_already_exists,
                },
                { 'border-danger text-danger': errors.studentCode || errors.error_loading_student_code }
              )}
            />

            <Form.Text className="text-white">O código é composto por letras e números, separados por traços</Form.Text>
          </Form.Group>
          <div className="d-flex flex-row justify-content-start align-items-center button-wrapper">
            <Button variant="light" className="text-primary" disabled={isLoading} type="submit">
              {isLoading ? 'Carregando' : 'Carregar'} dados do aluno
            </Button>
            {isLoading && <AppLoader variant="white" />}
          </div>
        </Form>
        <Container
          className={classNames('m-0 p-3 mt-5', {
            'error-container': !!errorMessage,
          })}
        >
          {errorMessage}
        </Container>
      </Container>
    </Col>
  );
};
