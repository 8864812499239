import { MouseEventHandler, ReactNode, useCallback } from 'react';
import { Button, Modal, ModalProps } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';

export interface ButtonConfig {
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  variant?: string;
}

export interface AppModalProps {
  show?: boolean;
  onHide?: () => void;
  fullscreen?: string | true;
  closeButton?: boolean;
  confirmClose?: boolean;
  title?: string | ReactNode | Array<ReactNode>;
  children: string | ReactNode | Array<ReactNode>;
  buttons?: Array<ButtonConfig>;
  size?: ModalProps['size'];
  animation?: boolean;
  backdrop?: boolean | 'static';
  keyboard?: boolean;
  dialogClassname?: string;
  wrapperClassname?: string;
  titleClassname?: string;
  bodyClassname?: string;
}

export const AppModal = ({
  show,
  onHide,
  fullscreen,
  closeButton = false,
  confirmClose = false,
  title,
  children,
  buttons,
  size = 'sm',
  animation = true,
  backdrop,
  keyboard,
  dialogClassname,
  wrapperClassname,
  titleClassname,
  bodyClassname,
}: AppModalProps) => {
  const confirmCloseAction = useCallback(() => {
    if (window.confirm('Você tem certeza que deseja fechar esta tela?\nPoderá haver perda de dados.')) {
      onHide?.();
    }
  }, [onHide]);

  return (
    <Modal
      show={show}
      onHide={confirmClose ? confirmCloseAction : onHide}
      fullscreen={fullscreen}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={classNames('app-modal', wrapperClassname)}
      dialogClassName={dialogClassname}
      animation={animation}
      backdrop={backdrop}
      keyboard={keyboard}
    >
      {title && (
        <Modal.Header className={classNames('border-0', titleClassname)}>
          <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className={bodyClassname}>
        {children}
        {closeButton && (
          <button className="modal-close-button fs-5 align-middle" onClick={confirmClose ? confirmCloseAction : onHide}>
            <FontAwesomeIcon icon={faXmark} /> <span>Fechar</span>
          </button>
        )}
      </Modal.Body>
      {buttons && (
        <Modal.Footer className="border-0">
          {buttons?.map((buttonConfig, index) => (
            <Button
              key={`modal-${index}`}
              onClick={buttonConfig.onClick}
              className={buttonConfig.className}
              variant={buttonConfig.variant}
            >
              {buttonConfig.label}
            </Button>
          ))}
        </Modal.Footer>
      )}
    </Modal>
  );
};
