import { initFirebase } from 'services/firebase/initFirebase';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, StorageError } from 'firebase/storage';
import { BASE_STORAGE_URL } from '..';

initFirebase();
const storage = getStorage();

export const uploadBytes = (
  remoteFilepath: string,
  data: Blob | File,
  onProgressCallback?: (progress: number) => void,
  onErrorCallback?: (error: StorageError) => void,
  onSuccessCallback?: (downloadUrl: string) => void
): void => {
  const url = `${BASE_STORAGE_URL}/${remoteFilepath}`;
  const metadata = {
    contentType: 'image/jpeg',
  };

  const storageRef = ref(storage, url);
  const uploadTask = uploadBytesResumable(storageRef, data, metadata);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgressCallback?.(progress);
    },
    (error) => {
      // Handle unsuccessful uploads
      onErrorCallback?.(error);
    },
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        onSuccessCallback?.(url);
      });
    }
  );
};
