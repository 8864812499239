import { DebounceInput } from 'react-debounce-input';

interface AmountSelectorProps {
  id?: string;
  type?: string;
  min?: string | number;
  placeholder?: string;
  className?: string;
  onChange?: (value: string) => void;
  value?: string | number;
  disabled?: boolean;
  size?: 'sm' | 'lg';
}

export const AmountSelector = ({
  id,
  type,
  min,
  placeholder,
  className,
  onChange,
  value,
  size,
}: AmountSelectorProps) => {
  return (
    <DebounceInput
      id={id}
      type={type}
      min={min}
      debounceTimeout={100}
      placeholder={placeholder}
      className={className}
      onChange={(e) => {
        onChange?.(e.target.value);
      }}
      value={value}
      // disabled={updatingAmount === productCartKey}
      size={size}
      onDrop={() => false}
      onPaste={() => false}
    />
  );
};
