import { Spinner } from 'react-bootstrap';
import classNames from 'classnames';

interface AppLoaderProps {
  variant?: string;
  className?: string;
}

export const AppLoader = ({ variant = 'primary', className }: AppLoaderProps) => {
  return (
    <div className={classNames('d-flex flex-grow-1 justify-content-center align-items-center h-100', className)}>
      <Spinner animation="border" variant={variant} />
    </div>
  );
};
