import { StorageError } from 'firebase/storage';
import { uploadBytes } from './impl';

export const BASE_STORAGE_URL = '/shopping';

export interface IFirebaseStorageService {
  uploadBytes: (
    remoteFilepath: string,
    data: Blob | File,
    onProgressCallback?: (progress: number) => void,
    onErrorCallback?: (error: StorageError) => void,
    onSuccessCallback?: (downloadUrl: string) => void
  ) => void;
}

export const firebaseStorageService: IFirebaseStorageService = {
  uploadBytes,
};
