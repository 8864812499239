import { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectIsAuthenticated, selectIsLoading, selectAuthUser, selectAccountInfo } from "features/authSlice";

export const useAuth = () => {
  const user = useSelector(selectAuthUser);
  const accountInfo = useSelector(selectAccountInfo);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const isLoading = useSelector(selectIsLoading);

  return useMemo(
    () => ({
      user,
      accountInfo,
      isAuthenticated,
      isLoading,
    }),
    [user, accountInfo, isAuthenticated, isLoading]
  );
};
