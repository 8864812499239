import {
  getDatabase,
  DatabaseReference,
  ref,
  DataSnapshot,
  onValue,
  get,
  query,
  orderByChild,
  equalTo,
  Query,
} from 'firebase/database';
import { initFirebase } from 'services/firebase/initFirebase';
import { Order } from 'types';
import { BASE_DATABASE_URL } from '..';

initFirebase();
const database = getDatabase();

export const listenPaymentOrder = (
  paymentId: string,
  callback: (order: Order | null) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => {
  if (!paymentId?.trim?.()) {
    if (errorCallback) {
      errorCallback(new Error('invalid-payment-id'));
    }
    return () => null;
  }

  const paymentOrderRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/orders/${paymentId}`);

  const unsubscribe = onValue(
    paymentOrderRef,
    (snapshot: DataSnapshot) => {
      if (snapshot.exists()) {
        const paymentOrder: Order = snapshot.val();

        callback(paymentOrder);
      } else {
        callback(null);
      }
    },
    errorCallback
  );

  return unsubscribe;
};

export const getDiscountValue = async (discountCode: string): Promise<number> => {
  if (!discountCode?.trim?.()) {
    throw new Error('invalid-discount-code');
  }

  const availableDiscountCodeRef: DatabaseReference = ref(
    database,
    `${BASE_DATABASE_URL}/discountCodes/${discountCode?.trim().toUpperCase()}`
  );
  const availableDiscountCodeSnapshot = await get(availableDiscountCodeRef);
  const availableDiscountCode = availableDiscountCodeSnapshot.val();

  if (availableDiscountCodeSnapshot.exists() && availableDiscountCode) {
    return availableDiscountCode;
  }

  return 0.0;
};

export const listenExistingOrders = (
  uid: string,
  callback: (orders: Array<Order>) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => {
  if (!uid?.trim?.()) {
    if (errorCallback) {
      errorCallback(new Error('invalid-uid'));
    }
    return () => null;
  }

  const paymentOrderRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/orders`);
  const paymentOrderByUid: Query = query(paymentOrderRef, orderByChild('uid'), equalTo(uid));

  const unsubscribe = onValue(
    paymentOrderByUid,
    (snapshot: DataSnapshot) => {
      const orderList: Array<Order> = [];

      try {
        snapshot.forEach((childSnapshot) => {
          const paymentOrder: Order = childSnapshot.val();

          paymentOrder.orderId = childSnapshot.key ?? '';

          orderList.push(paymentOrder);
        });

        callback(orderList);
      } catch (error) {
        if (errorCallback) {
          errorCallback(error as Error);
        }
      }
    },
    errorCallback
  );

  return unsubscribe;
};
