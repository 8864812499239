import { ReactNode } from 'react';
import { Container } from 'react-bootstrap';

import guidoError from 'assets/images/guido_error.svg';

import './styles.scss';

interface OperationErrorProps {
  title?: string | ReactNode;
  body?: string | ReactNode;
}

export const OperationError = ({
  title = 'Ocorreu um erro realizando esta operação',
  body = (
    <>
      Tente recarregar esta página ou entre em contato com a equipe da{' '}
      <a target="_blank" href="https://www.imaginakids.com.br/" rel="noreferrer">
        ImaginaKIDS
      </a>
    </>
  ),
}: OperationErrorProps) => {
  return (
    <Container className="text-center py-3 px-15">
      <h3 className="fw-bold p-0 m-0 mb-4">{title}</h3>
      <img src={guidoError} alt="Pinguim segurando uma placa de pare" className="p-0 m-0 mb-4 error-image" />
      <h5 className="p-0 m-0">{body}</h5>
    </Container>
  );
};
