import { useRef } from 'react';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import { Wizard } from 'react-use-wizard';
import { AnimatePresence } from 'framer-motion';

import { useAppSelector } from 'store/hooks';
import { selectWizardStep } from 'features/studentRegistrationSlice';

import { AppModal } from 'components/AppModal';
import { AnimatedStep } from './AnimatedStep';
import { RegisterStudent } from './RegisterStudent';
import { ConfirmStudent } from './ConfirmStudent';
import { BiographyStudent } from './BiographyStudent';
import { PhotoStudent } from './PhotoStudent';

import './styles.scss';

interface ModalAddStudentProps {
  show: boolean;
  onHide?: () => void;
}

export const AddStudentModal = ({ show, onHide }: ModalAddStudentProps) => {
  const wizardStep = useAppSelector(selectWizardStep);
  const previousStepRef = useRef<number>(0);

  return (
    <AppModal
      show={show}
      confirmClose
      onHide={onHide}
      fullscreen
      closeButton
      bodyClassname="px-5 py-0 text-white bg-transparent"
      titleClassname="px-5 py-4 text-white bg-transparent"
      wrapperClassname="modal-addstudent"
    >
      <div className={classNames('d-flex flex-1 justify-content-center align-items-center', 'h-100 ', 'p-0 m-0')}>
        <Container className="p-0 m-0 position-relative">
          <Wizard wrapper={<AnimatePresence initial={false} />} startIndex={wizardStep}>
            <AnimatedStep previousStep={previousStepRef}>
              <RegisterStudent />
            </AnimatedStep>
            <AnimatedStep previousStep={previousStepRef}>
              <ConfirmStudent closeModalDisabledBiography={onHide} />
            </AnimatedStep>
            <AnimatedStep previousStep={previousStepRef}>
              <BiographyStudent />
            </AnimatedStep>
            <AnimatedStep previousStep={previousStepRef}>
              <PhotoStudent closeModal={onHide} />
            </AnimatedStep>
          </Wizard>
        </Container>
      </div>
    </AppModal>
  );
};
