import { useMemo } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

import { useAppSelector } from 'store/hooks';
import { AccountInfo } from 'types';
import { toCurrency } from 'utils';
import { selectDiscountValue } from 'features/shoppingSlice';

import './styles.scss';

interface PaymentReviewProps {
  accountInfo: AccountInfo | null;
  totalCartPrice: number;
  countProduct: number;
  countBooks: number;
}

export const PaymentReview = ({ accountInfo, totalCartPrice, countProduct, countBooks }: PaymentReviewProps) => {
  const discountValue = useAppSelector(selectDiscountValue);

  const discountFixed = useMemo(
    () => totalCartPrice * ((discountValue ?? 0.0) / 100.0),
    [discountValue, totalCartPrice]
  );

  return (
    <Container className="review-order-container">
      <Row>
        <Col lg={6} className="p-0 m-0 my-2 pe-lg-3">
          <Card className="h-100">
            <Card.Header as="h5" className="bg-primary text-white border-0">
              Dados do comprador
            </Card.Header>
            <Card.Body className="fw-normal text-black">
              <div className="fw-bolder">{accountInfo?.customerName}</div>
              <div className="fw-normal customer-info">Email: {accountInfo?.customerEmail}</div>
              <div className="fw-normal customer-info">Celular: {accountInfo?.customerPhone}</div>
              <div className="fw-normal customer-info">CEP: {accountInfo?.customerCep}</div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6} className="p-0 m-0 my-2 ps-lg-3">
          <Card className="h-100">
            <Card.Header as="h5" className="bg-primary text-white border-0">
              Resumo da compra
            </Card.Header>
            <Card.Body className="fw-normal text-black">
              <div className="d-flex flex-row justify-content-between align-items-center w-100 my-2">
                <span className="fw-normal">{countProduct} x Produtos</span>
                <span className="fw-normal">{countBooks} livros</span>
              </div>
              <span className="divider" />
              <div className="d-flex flex-row justify-content-between align-items-center w-100 my-2">
                <span className="fw-bolder">Total</span>
                <span className="fw-bolder">{toCurrency(totalCartPrice)}</span>
              </div>
              {(discountValue ?? 0.0) > 0.0 && (
                <>
                  <div className="d-flex flex-row justify-content-between align-items-center w-100 my-2">
                    <span className="fst-italic">Desconto ({discountValue ?? 0.0}%)</span>
                    <span className="fst-italic">- {toCurrency(discountFixed)}</span>
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center w-100 my-2">
                    <span className="fw-bolder fst-italic">Total</span>
                    <span className="fw-bolder fst-italic">{toCurrency(totalCartPrice - discountFixed)}</span>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
