import { AccountInfo, User, Student, Product, Cart, ProductCart, Order } from 'types';
import { firebaseDatabaseService } from 'services/firebase/database';

export const registerUser = async (authUser: User, name: string, phone: string): Promise<User> => {
  const userRegistry: AccountInfo = { ...authUser, name, phone };
  await firebaseDatabaseService.registerUser(userRegistry);
  return userRegistry;
};

export const registerStudentData = (uid: string, studentData: Student, updateStudentKey?: string): Promise<void> =>
  firebaseDatabaseService.registerStudentData(uid, studentData, updateStudentKey);

export const deleteStudentData = (uid: string, studentId: string): Promise<void> =>
  firebaseDatabaseService.deleteStudentData(uid, studentId);

export const getAvailableProductList = (schoolCode: string): Promise<Array<Product>> =>
  firebaseDatabaseService.getAvailableProductList(schoolCode);

export const addProductToCart = (uid: string, productCart: ProductCart, lastUpdateDate: Date): Promise<void> =>
  firebaseDatabaseService.addProductToCart(uid, productCart, lastUpdateDate);

export const updateAmountProductCart = (
  uid: string,
  productCode: string,
  studentId: string,
  lastUpdateDate: Date,
  newAmount: number
): Promise<void> =>
  firebaseDatabaseService.updateAmountProductCart(uid, productCode, studentId, lastUpdateDate, newAmount);

export const deleteProductCart = (
  uid: string,
  productCode: string,
  studentId: string,
  lastUpdateDate: Date
): Promise<void> => firebaseDatabaseService.deleteProductCart(uid, productCode, studentId, lastUpdateDate);

export const listenAccountInfo = (
  uid: string,
  callback: (accountInfo: AccountInfo) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => firebaseDatabaseService.listenAccountInfo(uid, callback, errorCallback);

export const listenStudentList = (
  uid: string,
  callback: (studentDataList: Array<Student>) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => firebaseDatabaseService.listenStudentList(uid, callback, errorCallback);

export const listenProductCart = (
  uid: string,
  callback: (cart: Cart) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => firebaseDatabaseService.listenProductCart(uid, callback, errorCallback);

export const listenPaymentOrder = (
  paymentId: string,
  callback: (order: Order | null) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => firebaseDatabaseService.listenPaymentOrder(paymentId, callback, errorCallback);

export const getDiscountValue = (discountCode: string): Promise<number> =>
  firebaseDatabaseService.getDiscountValue(discountCode);

export const listenExistingOrders = (
  uid: string,
  callback: (orders: Array<Order>) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => firebaseDatabaseService.listenExistingOrders(uid, callback, errorCallback);
