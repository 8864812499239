import { uploadBytes } from './storageImpl';

export interface IStorageService {
  uploadBytes: (
    remoteFilepath: string,
    data: Blob | File,
    onProgressCallback?: (progress: number) => void,
    onErrorCallback?: (error: Error) => void,
    onSuccessCallback?: (downloadUrl: string) => void
  ) => void;
}

export const storageService: IStorageService = {
  uploadBytes,
};
