import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { ProductCart, Student } from 'types';

export interface CartState {
  uid: string;
  products: Array<ProductCart>;
  lastUpdateDate: Date | null;
}

export interface StudentListState {
  isLoading: boolean;
  isError: boolean;
  data: Array<Student> | null;
}

export interface ShoppingState {
  selectedStudentId: string | null;
  studentListData: StudentListState;
  showCart: boolean;
  isLoadingCart: boolean;
  isErrorCart: boolean;
  cart: CartState;
  lastPaymentDone: string;
  discount: number | null; // in percentage
}

const shoppingSlice = createSlice({
  name: 'shopping',
  initialState: {
    selectedStudentId: null,
    studentListData: {
      isLoading: true,
      isError: false,
      data: null,
    },
    showCart: false,
    isLoadingCart: true,
    isErrorCart: false,
    cart: {
      uid: '',
      lastUpdateDate: null,
      products: [],
    } as CartState,
    lastPaymentDone: '',
    discount: null,
  } as ShoppingState,
  reducers: {
    setSelectedStudentId: (state, { payload: selectedStudentId }: PayloadAction<string | null>) => {
      state.selectedStudentId = selectedStudentId;
    },
    setStudentListData: (state, { payload: studentListData }: PayloadAction<StudentListState>) => {
      state.studentListData = studentListData;
    },
    setShowCart: (state, { payload: showCart }: PayloadAction<boolean>) => {
      state.showCart = showCart;
    },
    setIsLoadingCart: (state, { payload: isLoadingCart }: PayloadAction<boolean>) => {
      state.isLoadingCart = isLoadingCart;
    },
    setIsErrorCart: (state, { payload: isErrorCart }: PayloadAction<boolean>) => {
      state.isErrorCart = isErrorCart;
    },
    setCartState: (state, { payload: cartState }: PayloadAction<CartState>) => {
      state.cart = cartState;
    },
    setLastPaymentDone: (state, { payload: lastPaymentDone }: PayloadAction<string>) => {
      state.lastPaymentDone = lastPaymentDone;
    },
    setDiscountValue: (state, { payload: discount }: PayloadAction<number | null>) => {
      state.discount = discount;
    },
  },
});

export const {
  setSelectedStudentId,
  setStudentListData,
  setShowCart,
  setIsLoadingCart,
  setIsErrorCart,
  setCartState,
  setLastPaymentDone,
  setDiscountValue,
} = shoppingSlice.actions;

export default shoppingSlice.reducer;

export const selectSelectedStudent = (state: RootState) =>
  state.shopping.studentListData.data?.find((s) => s.id === state.shopping.selectedStudentId);

export const selectSelectedStudentId = (state: RootState) => state.shopping.selectedStudentId;

export const selectStudentListData = (state: RootState) => state.shopping.studentListData;
export const selectShowCart = (state: RootState) => state.shopping.showCart;
export const selectIsLoadingCart = (state: RootState) => state.shopping.isLoadingCart;
export const selectIsErrorCart = (state: RootState) => state.shopping.isErrorCart;
export const selectCartState = (state: RootState) => state.shopping.cart;
export const selectLastPaymentDone = (state: RootState) => state.shopping.lastPaymentDone;
export const selectDiscountValue = (state: RootState) => state.shopping.discount;
