import { AmountSelector } from 'components/AmountSelector';
import { setShowCart } from 'features/shoppingSlice';
import { useCallback, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useAppDispatch } from 'store/hooks';
import { BookType } from 'types';
import { toCurrency } from 'utils';
import { AddToCartButton } from './AddToCartButton';

import './styles.scss';

interface ProductCardProps {
  productCode: string;
  imageSrc: string;
  imageAltText?: string;
  title: string;
  bookType: BookType;
  price: number;
  amount: number;
  productDescription: string;
  salesDescription: string;
  badge?: string;
  incompleteStudentData?: boolean;
  onAddProductCart: (productCode: string, amount: number) => Promise<boolean>;
}

export const ProductCard = ({
  productCode,
  imageSrc,
  imageAltText,
  title,
  bookType,
  price,
  amount,
  productDescription,
  salesDescription,
  badge,
  incompleteStudentData = false,
  onAddProductCart,
}: ProductCardProps) => {
  const dispatch = useAppDispatch();
  const [productAmount, setProductAmount] = useState(1);

  const onClickAddProduct = useCallback(async (): Promise<void> => {
    if (!productAmount || isNaN(productAmount)) {
      alert('Quantidade inválida de produtos! Informe uma quantidade válida e tente adicionar novamente!');
      return;
    }

    setProductAmount(1);
    onAddProductCart(productCode, productAmount);
    dispatch(setShowCart(true));
  }, [productCode, productAmount, onAddProductCart, dispatch]);

  return (
    <Card className="h-100 position-relative product-card">
      {badge && <div className="product-badge">{badge}</div>}
      <div className="card-img-top py-6 product-cover">
        <Card.Img variant="top" src={imageSrc} alt={imageAltText} className="rounded mx-auto d-block" />
      </div>
      <Card.Body>
        <Card.Title className="fw-bolder m-0">{title}</Card.Title>
        <p className="mb-2 book-type">{bookType === BookType.hardCover ? 'Capa dura' : 'Capa flexível'}</p>
        <div className="sales-description">{salesDescription}</div>
        <div className="mb-2 price-amount">
          {toCurrency(price)} - {amount} livros
        </div>
        <div className="mb-4 product-description">{productDescription}</div>
      </Card.Body>
      <Card.Footer className="p-2">
        <div className="d-flex flex-row justify-content-between align-items-center gap-2 product-add-to-cart">
          <AmountSelector
            id="amountToAdd"
            type="number"
            min={1}
            placeholder="Quantidade"
            className="form-control text-center"
            onChange={(val) => setProductAmount(parseInt(val || '0'))}
            value={productAmount === 0 ? '' : productAmount}
          />
          <AddToCartButton onClick={onClickAddProduct} incompleteStudentData={incompleteStudentData} />
        </div>
      </Card.Footer>
    </Card>
  );
};
