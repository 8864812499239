import { useEffect, useMemo } from 'react';
import { Col, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Stepper, Step } from 'react-form-stepper';

import { ROUTES } from 'constants/';
import { useAuth } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AppLoader } from 'components';
import {
  selectCartState,
  selectDiscountValue,
  selectIsErrorCart,
  selectIsLoadingCart,
  setDiscountValue,
} from 'features/shoppingSlice';

import { PaymentReview } from './PaymentReview';
import { PaymentMethod } from './PaymentMethod';
import { OperationError } from '../OperationError';
import { PaymentDiscount } from './PaymentDiscount';

const PaymentOrder = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading, accountInfo } = useAuth();

  const cartState = useAppSelector(selectCartState);
  const isLoadingCart = useAppSelector(selectIsLoadingCart);
  const isErrorCart = useAppSelector(selectIsErrorCart);

  const discountValue = useAppSelector(selectDiscountValue);

  const discountPercentage = useMemo(() => discountValue ?? 0.0, [discountValue]);

  const { totalCartPrice, countProduct, countBooks } = useMemo(() => {
    const totalCartPrice = cartState?.products.reduce((result, prod) => {
      result += prod.amount * prod.price;
      return result;
    }, 0.0);

    const countProduct = cartState?.products.length;

    const countBooks = cartState?.products.reduce((result, prod) => {
      result += prod.amount * prod.bookAmount;
      return result;
    }, 0);

    return { totalCartPrice, countProduct, countBooks };
  }, [cartState]);

  useEffect(() => {
    if (
      !isLoading &&
      !isLoadingCart &&
      (!accountInfo?.customerName ||
        !accountInfo?.customerEmail ||
        !accountInfo?.customerCpf ||
        !accountInfo?.customerPhone ||
        !accountInfo?.customerCep ||
        !accountInfo?.customerAdressNumber ||
        !accountInfo?.customerProviderId)
      // || (totalCartPrice === 0.0 && countProduct === 0))
    ) {
      navigate(ROUTES.CHECKOUT);
    }
  }, [isLoading, isLoadingCart, accountInfo, totalCartPrice, countProduct, navigate]);

  useEffect(() => {
    document.body.scrollTop = 0;

    return () => {
      dispatch(setDiscountValue(null));
    };
  }, [dispatch]);

  return (
    <div className="div-center">
      <div className="row">
        <Col className="p-4 p-md-8">
          <Container className="checkout-container">
            {isLoading || isLoadingCart ? (
              <AppLoader />
            ) : isErrorCart ? (
              <OperationError />
            ) : (
              <>
                <Container className="px-0 mb-6">
                  <Stepper activeStep={1} className="p-0">
                    <Step label="Pedido" className="rounded-stepper" onClick={() => navigate(ROUTES.CHECKOUT)} />
                    <Step label="Pagamento" className="rounded-stepper" onClick={() => true} />
                    <Step label="Confirmação" className="rounded-stepper" disabled />
                  </Stepper>
                </Container>
                <h2 className="fw-bolder m-0 p-0">Pagamento</h2>
                <section className="mb-8 checkout-section" data-title="3. Revisar dados da compra">
                  <PaymentReview
                    accountInfo={accountInfo}
                    totalCartPrice={totalCartPrice}
                    countProduct={countProduct}
                    countBooks={countBooks}
                  />
                </section>
                <section className="mb-4 checkout-section" data-title="4. Possui cupom ou vale presente?">
                  <PaymentDiscount />
                </section>
                <section className="mb-8 checkout-section" data-title="5. Formas de pagamento">
                  <PaymentMethod
                    totalCartPrice={totalCartPrice}
                    discountPercentage={discountPercentage}
                    countBooks={countBooks}
                  />
                </section>
              </>
            )}
          </Container>
        </Col>
      </div>
    </div>
  );
};

export default PaymentOrder;
