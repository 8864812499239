import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Button, Col, Container, Form } from 'react-bootstrap';
import { useWizard } from 'react-use-wizard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import ReactCrop, { centerCrop, makeAspectCrop, Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Student } from 'types';
import { useAuth } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { databaseService } from 'services/database';
import { storageService } from 'services/storage';
import { AppModal } from 'components/AppModal';
import { AppLoader } from 'components/AppLoader';
import {
  selectBiographyText,
  selectEditMode,
  selectImagePermission,
  selectPhotoUrl,
  selectStudentRegistrationData,
  setImagePermission,
  setPhotoUrl,
} from 'features/studentRegistrationSlice';

import { getCroppedImageAsBase64, base64ToFile } from './helpers';

import './styles.scss';

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 50,
        height: 50,
      },
      1 / 1,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface PhotoStudentProps {
  closeModal?: () => void;
}

export const PhotoStudent = ({ closeModal }: PhotoStudentProps) => {
  const dispatch = useAppDispatch();
  const { schoolCode, schoolName, studentData, disableBiography } = useAppSelector(selectStudentRegistrationData);
  const biography = useAppSelector(selectBiographyText);
  const photoUrl = useAppSelector(selectPhotoUrl);
  const isEditMode = useAppSelector(selectEditMode);
  const imagePermission = useAppSelector(selectImagePermission);

  const { user } = useAuth();

  const { previousStep, goToStep } = useWizard();

  const imgRef = useRef<HTMLImageElement>(null);

  const [imgSrc, setImgSrc] = useState('');
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const [crop, setCrop] = useState<Crop>();

  const [photoUrlLoaded, setPhotoUrlLoaded] = useState(false);

  useEffect(() => {
    if (!studentData || !schoolCode || !schoolName) {
      goToStep(0);
    }
  }, [studentData, schoolCode, schoolName, goToStep]);

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '');
        setUploadedFile(e.target.files?.[0]);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height));
  }

  const closeCropModal = useCallback(() => {
    setUploadedFile(undefined);
    setImgSrc('');
    setIsUploading(false);
  }, []);

  const onProgressCallback = useCallback((progress: number): void => {
    setProgress(progress);
  }, []);

  const onErrorCallback = useCallback((error: Error): void => {
    console.log(error);
    alert(error?.message ?? 'Erro realizando operação');
    setIsUploading(false);
  }, []);

  const onSuccessCallback = useCallback(
    (downloadUrl: string): void => {
      dispatch(setPhotoUrl(downloadUrl));
      setIsUploading(false);
    },
    [dispatch]
  );

  const cropAndUploadImage = useCallback(async () => {
    try {
      const studentCode = studentData?.studentCode;

      if (!studentCode) {
        throw new Error('Erro obtendo código do aluno');
      }

      const base64Image = await getCroppedImageAsBase64(imgRef.current, crop);
      const croppedFile = base64ToFile(base64Image, 'photo.jpg');

      if (!base64Image || !croppedFile) {
        throw new Error('Erro lendo dados da imagem');
      }

      dispatch(setPhotoUrl(''));
      setIsUploading(true);
      setImgSrc('');

      storageService.uploadBytes(
        `students/${studentCode}/photo.jpg`,
        croppedFile,
        onProgressCallback,
        onErrorCallback,
        onSuccessCallback
      );
    } catch (err) {
      const error: Error = err as Error;

      console.log(error);
      alert(error?.message ?? 'Erro realizando operação');
    }
  }, [dispatch, crop, studentData, onProgressCallback, onErrorCallback, onSuccessCallback]);

  const confirmSave = useCallback(() => {
    const confirmMessage = isEditMode
      ? `Você tem certeza que deseja atualizar os dados do(a) aluno(a) "${studentData?.fullName}"`
      : `Você tem certeza que deseja salvar os dados do(a) aluno(a) "${studentData?.fullName}"`;

    if (window.confirm(confirmMessage)) {
      try {
        const studentRegisterData: Student = {
          id: studentData?.id ?? '',
          parentUid: user?.uid ?? '',
          schoolCode,
          schoolName,
          studentCode: studentData?.studentCode ?? '',
          studentName: studentData?.name ?? '',
          studentFullname: studentData?.fullName ?? '',
          studentClass: studentData?.classCode ?? '',
          imagePermission,
          biography,
          photoUrl,
          disableBiography: disableBiography ?? false,
        };

        if (isEditMode) {
          databaseService.registerStudentData(user?.uid ?? '', studentRegisterData, studentRegisterData.id);
        } else {
          databaseService.registerStudentData(user?.uid ?? '', studentRegisterData);
        }

        closeModal?.();
      } catch (error) {
        alert('Erro salvando dados do aluno.');
        console.log(error);
      }
    }
  }, [
    schoolCode,
    schoolName,
    studentData,
    biography,
    photoUrl,
    user?.uid,
    isEditMode,
    imagePermission,
    disableBiography,
    closeModal,
  ]);

  const uploadModal = useMemo(() => {
    return (
      <AppModal
        size="lg"
        show={!!imgSrc}
        onHide={closeCropModal}
        wrapperClassname="photo-student-modal"
        backdrop="static"
        fullscreen
      >
        <div
          className={classNames(
            'd-flex flex-1 flex-column justify-content-center align-items-center',
            'h-100',
            'p-0 m-0'
          )}
        >
          <div className="d-flex p-2 my-4 bg-white rounded-1 justify-content-center align-items-center">
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              aspect={1 / 1}
              circularCrop
              keepSelection
              minWidth={30}
            >
              <img
                ref={imgRef}
                alt="Pré-visualização da imagem"
                src={imgSrc}
                onLoad={onImageLoad}
                className="photo-student-preview"
              />
            </ReactCrop>
          </div>
          <span className="d-flex flex-row justify-content-around m-0 p-2 w-100">
            <Button variant="light" className="text-primary" type="button" size="lg" onClick={closeCropModal}>
              Cancelar
            </Button>
            <Button variant="success" className="me-5 text-white" type="button" size="lg" onClick={cropAndUploadImage}>
              Cortar
            </Button>
          </span>
        </div>
      </AppModal>
    );
  }, [closeCropModal, crop, cropAndUploadImage, imgSrc]);

  return (
    <Col className="photo-student">
      <Container>
        <h2>Foto</h2>
        <p>
          Agora envie uma foto do aluno no campo abaixo.{' '}
          <span className="fw-bolder">A FOTO DEVE ESTAR EM ALTA RESOLUÇÃO E FORMATO JPEG</span>
        </p>

        <Form className="col-lg-6">
          <Form.Control
            type="file"
            id="file-upload"
            accept="image/jpeg"
            value={uploadedFile?.name ?? ''}
            onChange={onSelectFile}
            placeholder="Selecionar uma foto"
          />

          <Container className="p-1 my-4 mx-0 bg-white text-primary rounded-circle photo-frame">
            {isUploading && !photoUrl && (
              <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100 text-primary">
                <AppLoader />
                <div>{progress.toFixed(2)}%</div>
              </div>
            )}
            {!isUploading && !imgSrc && !photoUrl && (
              <div className="d-flex justify-content-center align-items-center h-100 w-100">
                <FontAwesomeIcon icon={faUser} size="5x" />
              </div>
            )}
            {!isUploading && photoUrl && (
              <>
                <AppLoader className={classNames({ 'd-none': photoUrlLoaded })} />
                <img src={photoUrl} alt="" className="rounded-circle" onLoad={() => setPhotoUrlLoaded(true)} />
              </>
            )}
          </Container>
        </Form>
        <div className="m-0 px-0">
          <Form.Check
            type="checkbox"
            id="image-permission"
            className="cursor-pointer"
            label={
              <span className="image-permission">
                Autorizo a ImaginaKIDS a utilizar as imagens das obras produzidas e dos autores para fins de divulgação.{' '}
                <a href="https://www.imaginakids.com.br/autorizacao-imagem/" target="_blank" rel="noreferrer">
                  Saiba mais.
                </a>
              </span>
            }
            checked={imagePermission}
            onChange={() => dispatch(setImagePermission(!imagePermission))}
          />
        </div>
        <div className="m-0 p-2">
          <Button variant="light" className="me-5 text-primary" type="button" onClick={previousStep}>
            Voltar
          </Button>
          <Button variant="light" className="text-primary" type="button" onClick={confirmSave} disabled={isUploading}>
            Salvar
          </Button>
        </div>
      </Container>
      {uploadModal}
    </Col>
  );
};
