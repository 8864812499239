import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { AppLoader } from 'components';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { databaseService } from 'services/database';
import { functionsService } from 'services/functions';
import { selectLastPaymentDone, setLastPaymentDone } from 'features/shoppingSlice';

import PixLogo from 'assets/icons/PixLogo';

interface PixPaymentProps {
  totalCartPrice: number;
  discountPercentage: number;
  countBooks: number;
}

const PixPayment = ({ totalCartPrice, discountPercentage, countBooks }: PixPaymentProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const lastPaymentDone = useAppSelector(selectLastPaymentDone);

  const [showButton, setShowButton] = useState(true);

  const [loadingPaymentServer, setLoadingPaymentServer] = useState(false);
  const [errorPaymentServer, setErrorPaymentServer] = useState('');
  const [encodedQrCode, setEncodedQrCode] = useState<string | undefined>();

  const revealPix = useCallback(async () => {
    setShowButton(false);
    setLoadingPaymentServer(true);

    try {
      const paymentResult = await functionsService.paymentPix(
        totalCartPrice,
        `Compra de livros ImaginaKIDS [${countBooks} livro(s)]`,
        discountPercentage
      );

      dispatch(setLastPaymentDone(paymentResult.paymentId));
      const qrCodeData = paymentResult.pix;

      if (qrCodeData?.success) {
        setEncodedQrCode(qrCodeData.encodedImage);
        setErrorPaymentServer('');
        setLoadingPaymentServer(false);
      } else {
        setEncodedQrCode(undefined);
        setErrorPaymentServer(
          'Erro ao carregar o QR Code do Pix. Tente novamente mais tarde ou entre em contato com o suporte por meio de contato@imaginakids.com.br.'
        );
        setLoadingPaymentServer(false);
      }
    } catch (error: any) {
      setEncodedQrCode(undefined);
      setLoadingPaymentServer(false);

      const { details } = JSON.parse(JSON.stringify(error));

      if (details?.errors?.[0].description) {
        setErrorPaymentServer(details?.errors?.[0].description);
      } else {
        setErrorPaymentServer(error?.message);
      }

      document.body.scrollTop = document.body.scrollHeight;
    }
  }, [countBooks, totalCartPrice, discountPercentage, dispatch]);

  const pixComp = useMemo(() => {
    if (loadingPaymentServer) {
      return <AppLoader />;
    }

    if (errorPaymentServer) {
      return (
        <div className="text-danger text-center my-4">
          <div className="text-danger text-center my-2">{errorPaymentServer}</div>
          <Button variant="primary" className="mt-4 text-white" type="button" onClick={revealPix}>
            Tentar pagar novamente com Pix
          </Button>
        </div>
      );
    }

    return (
      <Container className="col-8 mb-6 p-0 w-100 text-center">
        <div className="d-flex flex-row justify-content-between align-items-center w-100 p-0 my-6">
          <div className="d-flex flex-1">
            <img
              src={`data:image/png;base64,${encodedQrCode}`}
              alt="QR code para pagamento com Pix"
              className="w-100"
            />
          </div>
          <div className="d-flex flex-1 text-start">
            <ol>
              <li>
                Abra o app do seu banco ou instituição financeira e entre no ambiente{' '}
                <span className="fw-bolder">Pix</span>
              </li>
              <li>Escolha a opção pagar com qr code e escaneie o código ao lado</li>
              <li>Confirme as informações e finalize a compra</li>
            </ol>
          </div>
        </div>
      </Container>
    );
  }, [loadingPaymentServer, errorPaymentServer, encodedQrCode, revealPix]);

  useEffect(() => {
    if (encodedQrCode && lastPaymentDone) {
      return databaseService.listenPaymentOrder(
        lastPaymentDone,
        (order) => {
          if (order?.status === 'CONFIRMED' || order?.status === 'RECEIVED') {
            navigate(`/payment-confirmation/${lastPaymentDone}`);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [encodedQrCode, lastPaymentDone, navigate]);

  return (
    <Row>
      <Col className="text-center mt-8">
        <Container className="col-8 mb-6">
          <h4 className="d-flex flex-row justify-content-center align-items-center gap-2 text-primary">
            <PixLogo className="pix-logo" /> Pagar com pix
          </h4>
        </Container>
        <Container className="col-8 mb-6 text-start">
          <p className="mb-3">
            Pague com <span className="fw-bolder">Pix</span> em qualquer dia e a qualquer hora! O pagamento é
            instantâneo, prático e pode ser feito em muito pouco tempo. É muito rápido e seguro :)
          </p>
          <p>
            Clique no botão abaixo para revelar o QR code e pagar com <span className="fw-bolder">Pix</span>.
          </p>
        </Container>
        <Container className="col-8 text-center">
          {showButton ? (
            <Button variant="primary" className="mt-4 text-white" type="button" onClick={revealPix}>
              Pagar com Pix
            </Button>
          ) : (
            pixComp
          )}
        </Container>
      </Col>
    </Row>
  );
};

export default PixPayment;
