import { initFirebase } from '../initFirebase';
import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { CreditCardData } from 'services/functions';

export interface IFirebaseFunctionsService {
  getStudentByCode: (studentCode: string) => Promise<HttpsCallableResult<unknown>>;
  registerCustomer: (
    fullName: string,
    email: string,
    cpf: string,
    mobilePhone: string,
    cep: string,
    adressNumber: string
  ) => Promise<HttpsCallableResult<unknown>>;
  paymentCreditCard: (
    totalValue: number,
    description: string,
    discountValue: number,
    creditCardData: CreditCardData,
    installmentCount?: number
  ) => Promise<HttpsCallableResult<unknown>>;
  paymentPix: (totalValue: number, description: string, discountValue: number) => Promise<HttpsCallableResult<unknown>>;
}

initFirebase();
const functions = getFunctions();

export const getStudentByCode = async (studentCode: string): Promise<HttpsCallableResult<unknown>> => {
  const findSchoolByStudentCode = httpsCallable(functions, 'findSchoolByStudentCode');
  return findSchoolByStudentCode({ studentCode });
};

export const registerCustomer = async (
  fullName: string,
  email: string,
  cpf: string,
  mobilePhone: string,
  cep: string,
  adressNumber: string
): Promise<HttpsCallableResult<unknown>> => {
  const registerCustomer = httpsCallable(functions, 'registerCustomer');
  return registerCustomer({ fullName, email, cpf, mobilePhone, cep, adressNumber });
};

export const paymentCreditCard = (
  totalValue: number,
  description: string,
  discountValue: number,
  creditCardData: CreditCardData,
  installmentCount?: number
): Promise<HttpsCallableResult<unknown>> => {
  const paymentGateway = httpsCallable(functions, 'paymentGateway');

  const stringDate = JSON.stringify(new Date()).replace(/"/gi, '');
  const formattedStringDate = stringDate.substring(0, stringDate.indexOf('T'));

  return paymentGateway({
    billingType: 'CREDIT_CARD',
    totalValue,
    dueDate: formattedStringDate,
    description,
    discountValue,
    creditCard: creditCardData,
    installmentCount: !installmentCount ? 1 : installmentCount,
  });
};

export const paymentPix = (
  totalValue: number,
  description: string,
  discountValue: number
): Promise<HttpsCallableResult<unknown>> => {
  const paymentGateway = httpsCallable(functions, 'paymentGateway');

  const stringDate = JSON.stringify(new Date()).replace(/"/gi, '');
  const formattedStringDate = stringDate.substring(0, stringDate.indexOf('T'));

  return paymentGateway({
    billingType: 'PIX',
    totalValue,
    dueDate: formattedStringDate,
    description,
    discountValue,
  });
};

export const firebaseFunctionsService: IFirebaseFunctionsService = {
  getStudentByCode,
  registerCustomer,
  paymentCreditCard,
  paymentPix,
};
