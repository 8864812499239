import React, { forwardRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AnchorProps, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import { Order } from 'types';
import { useAuth } from 'hooks';
import { toCurrency } from 'utils';
import { databaseService } from 'services/database';

import './styles.scss';

interface OrderHistoryIconProps extends AnchorProps {
  iconSize: SizeProp;
}

const OrderHistoryIcon = forwardRef<HTMLAnchorElement, OrderHistoryIconProps>((props, ref) => {
  return (
    <Link ref={ref} to="#" onClick={props.onClick}>
      <FontAwesomeIcon icon={faClockRotateLeft} size={props.iconSize} className="text-white" />
    </Link>
  );
});

interface OrderHistoryDropdownProps {
  menuAlignment?: 'start' | 'end';
  drop?: 'start' | 'end' | 'up' | 'down';
  iconSize?: SizeProp;
}

export const OrderHistoryDropdown = ({
  menuAlignment = 'end',
  drop = 'down',
  iconSize = 'sm',
}: OrderHistoryDropdownProps) => {
  const { user } = useAuth();

  const [existingOrders, setExistingOrders] = useState<Array<Order>>([]);

  useEffect(() => {
    if (user?.uid) {
      return databaseService.listenExistingOrders(
        user.uid,
        (orders) => {
          setExistingOrders(orders);
        },
        (error) => {
          console.log(error);
          setExistingOrders([]);
        }
      );
    }
  }, [user?.uid]);

  return (
    <Dropdown align={menuAlignment} drop={drop} className="order-history-dropdown">
      <Dropdown.Toggle as={OrderHistoryIcon} iconSize={iconSize} id="dropdown-order-history">
        Histórico de pedidos
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {existingOrders?.length > 0 ? (
          <>
            <Dropdown.Item className="bg-primary text-white">Histórico dos pedidos</Dropdown.Item>

            {existingOrders?.map((order, index) => (
              <React.Fragment key={`existing-order-${index}`}>
                <Dropdown.Item
                  eventKey={index}
                  className="text-primary"
                  href={order.transactionReceiptUrl || order.invoiceUrl || '#'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center w-100">
                    <div className="col-1">
                      <FontAwesomeIcon icon={faBook} />
                    </div>

                    <div className="col-6 text-black order-item">Pedido #{order.orderId?.substring(4)}</div>

                    <div className="col-3 text-black fw-bolder">
                      {order.totalInstallmentValue
                        ? toCurrency(order.totalInstallmentValue)
                        : toCurrency(order.totalValue)}
                    </div>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider className="p-0 m-0" />
              </React.Fragment>
            ))}
          </>
        ) : (
          <Dropdown.Item className="text-black">Não existem pedidos a serem exibidos</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};
