import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Container } from 'react-bootstrap';
import { Stepper, Step } from 'react-form-stepper';

import { ROUTES } from 'constants/';
import { useAuth } from 'hooks';
import { AppLoader } from 'components';
import { databaseService } from 'services/database';
import { OperationError } from '../OperationError';
import { WaitingPayment } from './WaitingPayment';
import { SuccessPayment } from './SuccessPayment';

const PaymentConfirmation = () => {
  const { paymentId } = useParams();

  const navigate = useNavigate();
  const { isLoading } = useAuth();

  const [waitingPayment, setWaitingPayment] = useState(true);
  const [errorWaitingPayment, setErrorWaitingPayment] = useState(false);

  useEffect(() => {
    if (!paymentId) {
      navigate(ROUTES.HOME);
      return;
    }

    return databaseService.listenPaymentOrder(
      paymentId,
      (order) => {
        if (order?.status === 'CONFIRMED' || order?.status === 'RECEIVED') {
          setErrorWaitingPayment(false);
          setWaitingPayment(false);
        }
      },
      (error) => {
        console.log(error);
        setErrorWaitingPayment(true);
        setWaitingPayment(false);
      }
    );
  }, [paymentId, navigate]);

  return (
    <div className="div-center">
      <div className="row">
        <Col className="p-4 p-md-8">
          <Container className="checkout-container">
            {isLoading ? (
              <AppLoader />
            ) : (
              <>
                <Container className="px-0 mb-6">
                  <Stepper activeStep={2} className="p-0">
                    <Step label="Pedido" className="rounded-stepper" onClick={() => true} />
                    <Step label="Pagamento" className="rounded-stepper" onClick={() => true} />
                    <Step label="Confirmação" className="rounded-stepper" onClick={() => true} />
                  </Stepper>
                </Container>
                <h2 className="fw-bolder m-0 p-0">Confirmação de pagamento</h2>
                <section className="mb-8 checkout-section" data-title="6. Confirmação de compra">
                  {waitingPayment ? (
                    <WaitingPayment paymentId={paymentId ?? ''} />
                  ) : errorWaitingPayment ? (
                    <OperationError title="Ocorreu um erro ao carregar os dados do seu pagamento" />
                  ) : (
                    <SuccessPayment paymentId={paymentId ?? ''} />
                  )}
                </section>
              </>
            )}
          </Container>
        </Col>
      </div>
    </div>
  );
};

export default PaymentConfirmation;
