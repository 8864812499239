import {
  getDatabase,
  DatabaseReference,
  ref,
  Query,
  query,
  orderByChild,
  equalTo,
  onValue,
  DataSnapshot,
  push,
  update,
  get,
} from 'firebase/database';
import { Student } from 'types';
import { BASE_DATABASE_URL } from '..';
import { initFirebase } from 'services/firebase/initFirebase';

initFirebase();
const database = getDatabase();

export const listenStudentList = (
  uid: string,
  callback: (studentDataList: Array<Student>) => unknown,
  errorCallback?: (error: Error) => unknown
): (() => void) => {
  if (!uid?.trim?.()) {
    if (errorCallback) {
      errorCallback(new Error('invalid-uid'));
    }

    return () => null;
  }

  const studentListRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/studentList`);
  const studentQueryListRef: Query = query(studentListRef, orderByChild('parentUid'), equalTo(uid));

  const unsubscribe = onValue(
    studentQueryListRef,
    (snapshot: DataSnapshot) => {
      const studentList: Array<Student> = [];

      try {
        snapshot.forEach((childSnapshot) => {
          const data = childSnapshot.val();

          const studentData: Student = {
            id: childSnapshot.key ?? '',
            parentUid: uid,
            schoolCode: data['schoolCode'] ?? '',
            schoolName: data['schoolName'] ?? '',
            studentCode: data['studentCode'] ?? '',
            studentName: data['studentName'] ?? '',
            studentFullname: data['studentFullname'] ?? '',
            studentClass: data['studentClass'] ?? '',
            biography: data['biography'] ?? '',
            imagePermission: data['imagePermission'] ?? false,
            photoUrl: data['photoUrl'] ?? '',
            disableBiography: data['disableBiography'] ?? false,
          };

          studentList.push(studentData);
        });

        callback(studentList);
      } catch (error) {
        if (errorCallback) {
          errorCallback(error as Error);
        }
      }
    },
    errorCallback
  );

  return unsubscribe;
};

export const registerStudentData = (uid: string, studentData: Student, updateStudentKey?: string): Promise<void> => {
  if (!uid) throw new Error('invalid-uid');
  if (!studentData) throw new Error('invalid-student-data');

  let studentKey: string = '';

  if (!updateStudentKey) {
    const studentListRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/studentList`);
    studentKey = push(studentListRef).key ?? '';
  } else {
    studentKey = updateStudentKey;
  }

  if (!studentKey) {
    throw new Error('error-registering-student');
  }

  const registerNewStudentUpdate = {
    [`${BASE_DATABASE_URL}/studentList/${studentKey}`]: {
      id: studentKey,
      parentUid: uid,
      schoolCode: studentData.schoolCode,
      schoolName: studentData.schoolName,
      studentCode: studentData.studentCode,
      studentName: studentData.studentName,
      studentFullname: studentData.studentFullname,
      studentClass: studentData.studentClass,
      biography: studentData.biography,
      imagePermission: studentData.imagePermission,
      photoUrl: studentData.photoUrl,
      disableBiography: studentData.disableBiography,
    },
  };

  return update(ref(database), registerNewStudentUpdate);
};

export const deleteStudentData = async (uid: string, studentId: string): Promise<void> => {
  if (!uid) throw new Error('invalid-uid');
  if (!studentId) throw new Error('invalid-student-id');

  // delete student's data
  const studentDataToDelete = {
    [`${BASE_DATABASE_URL}/studentList/${studentId}`]: null,
  };

  // find & delete cart references
  const productListRef: DatabaseReference = ref(database, `${BASE_DATABASE_URL}/carts/${uid}/products`);
  const productQueryListRef: Query = query(productListRef, orderByChild('studentId'), equalTo(studentId));

  const snapshot: DataSnapshot = await get(productQueryListRef);

  snapshot.forEach((productSnapshot) => {
    studentDataToDelete[`${BASE_DATABASE_URL}/carts/${uid}/products/${productSnapshot.key}`] = null;
  });

  return update(ref(database), studentDataToDelete);
};
