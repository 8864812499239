import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IMaskInput } from 'react-imask';

import { ROUTES } from 'constants/';
import { useAuth } from 'hooks';
import { validateCpf } from 'utils';
import { CepQueryResult, functionsService } from 'services/functions';

import { AppLoader } from 'components';

import './styles.scss';

export const CustomerRegistration = () => {
  const navigate = useNavigate();
  const { accountInfo } = useAuth();

  const [isLoadingCepServer, setIsLoadingCepServer] = useState(false);
  const [isErrorCepServer, setIsErrorCepServer] = useState(false);
  const [dataCep, setDataCep] = useState<CepQueryResult | undefined>();

  const [loadingCheckoutServer, setLoadingCheckoutServer] = useState(false);

  const validationSchema = object().shape({
    name: string().label('name').required('O nome é de preenchimento obrigatório'),
    email: string()
      .label('email')
      .email('O email informado é inválido')
      .required('O email é de preenchimento obrigatório'),
    cpf: string()
      .label('cpf')
      .required('O CPF é de preenchimento obrigatório')
      .test('validate_cpf', 'Número de CPF inválido', validateCpf),
    mobilePhone: string()
      .label('mobilePhone')
      .required('O celular é de preenchimento obrigatório')
      .min(11, 'O número de celular precisa ter 11 dígitos (DDD + número)'),
    cep: string().label('cep').required('O CEP é de preenchimento obrigatório').min(8, 'O CEP precisa ter 8 dígitos'),
    adressNumber: string().label('adressNumber').required('O número do endereço é de preenchimento obrigatório'),
  });
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: accountInfo?.customerName,
      email: accountInfo?.customerEmail,
      cpf: accountInfo?.customerCpf,
      mobilePhone: accountInfo?.customerPhone,
      cep: accountInfo?.customerCep,
      adressNumber: accountInfo?.customerAdressNumber,
      error_server: null,
    },
  });

  const loadCepData = useCallback((cep: string) => {
    setIsErrorCepServer(false);
    setIsLoadingCepServer(true);

    functionsService
      .getAddressByCep(cep)
      .then((data) => {
        setDataCep(data);
        setIsErrorCepServer(false);
        setIsLoadingCepServer(false);
      })
      .catch((error) => {
        console.log(error);

        setDataCep(undefined);
        setIsErrorCepServer(true);
        setIsLoadingCepServer(false);
      });
  }, []);

  useEffect(() => {
    if (accountInfo) {
      reset({
        name: accountInfo?.customerName,
        email: accountInfo?.customerEmail,
        cpf: accountInfo?.customerCpf,
        mobilePhone: accountInfo?.customerPhone,
        cep: accountInfo?.customerCep,
        adressNumber: accountInfo?.customerAdressNumber,
        error_server: null,
      });
    }
  }, [accountInfo, reset, loadCepData]);

  useEffect(() => {
    const unmaskedCep = getValues().cep ?? '';

    if (unmaskedCep.length === 8) {
      loadCepData(unmaskedCep);
    }
  }, [loadCepData, getValues]);

  const handleSubmitCustomerData = useCallback(
    async ({ name, email, cpf, mobilePhone, cep, adressNumber }: FieldValues) => {
      clearErrors();
      setLoadingCheckoutServer(true);

      try {
        await functionsService.registerCustomer(name, email, cpf, mobilePhone, cep, adressNumber);

        setLoadingCheckoutServer(false);

        // navigate to payment screen
        navigate(ROUTES.PAYMENT);
      } catch (error: any) {
        clearErrors();
        setLoadingCheckoutServer(false);

        const { details } = JSON.parse(JSON.stringify(error));

        if (details?.errors?.[0].description) {
          setError('error_server', { type: 'server', message: details?.errors?.[0].description });
        } else {
          setError('error_server', { type: 'server', message: error?.message });
        }

        window.scrollTo(0, document.body.scrollHeight);
      }
    },
    [clearErrors, setError, navigate]
  );

  const errorMessage = useMemo(() => {
    if (errors.error_server) {
      const message = errors.error_server?.message ?? '';

      return (
        <Form.Text className="py-2 px-0 fw-bolder error-message">
          <FontAwesomeIcon icon={faCircleExclamation} /> {message}
        </Form.Text>
      );
    }

    return null;
  }, [errors.error_server]);

  return (
    <Row>
      <Col lg={6}>
        <Container className="customer-registration">
          <Form onSubmit={handleSubmit(handleSubmitCustomerData)}>
            <Form.Group className="mb-2 required" controlId="formName">
              <Form.Label className="control-label">Nome completo</Form.Label>
              <Form.Control {...register('name')} type="text" placeholder="Nome completo" />
              <div className="field-error-text">
                {errors.name && <Form.Text className="text-danger fs-7">{errors.name.message ?? ''}</Form.Text>}
              </div>
            </Form.Group>

            <Form.Group className="mb-2 required" controlId="formEmail">
              <Form.Label className="control-label">Email</Form.Label>
              <Form.Control {...register('email')} type="email" placeholder="Email" />
              <div className="field-error-text">
                {errors.email && <Form.Text className="text-danger fs-7">{errors.email.message ?? ''}</Form.Text>}
              </div>
            </Form.Group>

            <Form.Group className="mb-2 required" controlId="formCpf">
              <Form.Label className="control-label">CPF</Form.Label>

              <Form.Control
                {...register('cpf')}
                as={IMaskInput}
                mask="000.000.000-00"
                type="text"
                placeholder="000.000.000-00"
                unmask={true}
                onAccept={(value: unknown) => {
                  clearErrors();
                  setValue('cpf', value as string);
                }}
                defaultValue={accountInfo?.customerCpf ?? ''}
              />
              <div className="field-error-text">
                {errors.cpf && <Form.Text className="text-danger fs-7">{errors.cpf.message ?? ''}</Form.Text>}
              </div>
            </Form.Group>

            <Form.Group className="mb-3 required" controlId="formMobilePhone">
              <Form.Label className="control-label">Celular</Form.Label>

              <Form.Control
                {...register('mobilePhone')}
                as={IMaskInput}
                mask="(00)00000-0000"
                type="phone"
                placeholder="(00)00000-0000"
                unmask={true}
                onAccept={(value: unknown) => {
                  clearErrors();
                  setValue('mobilePhone', value as string);
                }}
                defaultValue={accountInfo?.customerPhone ?? ''}
              />
              <div className="field-error-text">
                {errors.mobilePhone && (
                  <Form.Text className="text-danger fs-7">{errors.mobilePhone.message ?? ''}</Form.Text>
                )}
              </div>
            </Form.Group>

            <Form.Group className="mb-3 required" controlId="formCep">
              <Form.Label className="control-label">CEP</Form.Label>

              <Form.Control
                {...register('cep')}
                as={IMaskInput}
                mask="00000-000"
                type="text"
                placeholder="00000-000"
                unmask={true}
                onAccept={(value: unknown) => {
                  const unmaskedCep: string = value as string;

                  clearErrors();
                  setValue('cep', unmaskedCep);

                  if (unmaskedCep.length === 8) {
                    loadCepData(unmaskedCep);
                  } else {
                    setDataCep(undefined);
                    setIsErrorCepServer(false);
                    setIsLoadingCepServer(false);
                  }
                }}
                defaultValue={accountInfo?.customerCep ?? ''}
              />
              <div className="field-error-text">
                {errors.cep && <Form.Text className="text-danger fs-7">{errors.cep.message ?? ''}</Form.Text>}
              </div>
            </Form.Group>

            {isLoadingCepServer && !isErrorCepServer && (
              <Container className="m-0 p-0 mb-3">
                <AppLoader />
              </Container>
            )}
            {!isLoadingCepServer && !isErrorCepServer && dataCep && dataCep?.erro !== 'true' && (
              <Container className="m-0 p-0 mb-3">
                <div>
                  <span className="fw-bolder">Endereço:</span> {dataCep.logradouro}, {dataCep.bairro}
                </div>
                <div>
                  <span className="fw-bolder">Cidade:</span> {dataCep.localidade} - {dataCep.uf}
                </div>
              </Container>
            )}
            {!isLoadingCepServer && (isErrorCepServer || dataCep?.erro === 'true') && (
              <Container className="m-0 p-0 mb-3 text-danger">Erro carregando dados do CEP.</Container>
            )}

            <Form.Group className="my-2 required" controlId="formNumber">
              <Form.Label className="control-label">Número (endereço)</Form.Label>
              <Form.Control
                {...register('adressNumber')}
                className="col-4"
                type="text"
                placeholder="Número"
                disabled={isLoadingCepServer || isErrorCepServer || !dataCep || dataCep?.erro === 'true'}
              />
              <div className="field-error-text">
                {errors.adressNumber && (
                  <Form.Text className="text-danger fs-7">{errors.adressNumber.message ?? ''}</Form.Text>
                )}
              </div>
            </Form.Group>

            <Button variant="primary" className="mt-4 text-white" type="submit">
              Continuar
            </Button>
          </Form>
          <Container
            className={classNames('m-0 p-4 mt-5 mb-8', {
              'error-container': !!errorMessage,
            })}
          >
            {errorMessage}
          </Container>
          {loadingCheckoutServer && (
            <div className="loading-server">
              <div className="position-absolute top-50 start-50 translate-middle">
                <AppLoader />
              </div>
            </div>
          )}
        </Container>
      </Col>
    </Row>
  );
};
