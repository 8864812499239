import React, { ReactNode, useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { motion, Variants } from 'framer-motion';

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 800 : -800,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 800 : -800,
      opacity: 0,
    };
  },
};

interface AnimatedStepProps {
  previousStep: React.MutableRefObject<number>;
  children: ReactNode;
}

export const AnimatedStep = React.memo(({ children, previousStep: previousStepIndex }: AnimatedStepProps) => {
  const { activeStep } = useWizard();

  useEffect(() => {
    return () => {
      previousStepIndex.current = activeStep;
    };
  }, [activeStep, previousStepIndex]);

  return (
    <motion.div
      custom={activeStep - previousStepIndex.current}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        type: 'spring',
        duration: 0.01,
        stiffness: 120,
        damping: 15,
      }}
    >
      <div className="position-absolute top-50 start-0 translate-middle-y w-100">{children}</div>
    </motion.div>
  );
});
