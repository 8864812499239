import { Container } from 'react-bootstrap';

import guidoNotfound from 'assets/images/guido_notfound404.svg';

import './styles.scss';

const PageNotFound = () => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center h-100 py-2 px-15">
      <h1 className="fw-bold p-0 m-0 mb-8">Ocorreu um problema...</h1>
      <img src={guidoNotfound} alt="Pinguim perdido com um mapa na mão" className="p-0 m-0 mb-8 notfound-image" />

      <h5 className="p-0 m-0">Parece que não encontramos o que você procura...</h5>
    </Container>
  );
};

export default PageNotFound;
