import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';

import './styles.scss';

interface AddToCartButtonProps {
  className?: string;
  incompleteStudentData?: boolean;
  onClick: () => Promise<void>;
}

export const AddToCartButton = ({ className, incompleteStudentData, onClick }: AddToCartButtonProps) => {
  return (
    <div
      {...(incompleteStudentData && {
        'data-tooltip': 'Edite e complete o cadastro do aluno antes de adquirir seus livros',
      })}
      className="button-add-cart"
    >
      <Button
        disabled={incompleteStudentData}
        className={classNames('text-white py-2 px-4 fs-6', className)}
        onClick={onClick}
      >
        <span className="d-flex flex-row justify-content-center align-items-center p-0 m-0">
          <FontAwesomeIcon icon={faPlus} className="me-2" /> Adicionar
        </span>
      </Button>
    </div>
  );
};
