import { User } from 'types';
import { createAuthUser, onAuthStateChanged, signInWithEmailAndPassword, signOut, forgetPassword } from './authImpl';

export interface IAuthService {
  createAuthUser: (email: string, password: string) => Promise<User>;
  onAuthStateChanged: (observer: (a: User | null) => void, error?: (a: Error) => void) => () => void;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<User>;
  signOut: () => Promise<void>;
  forgetPassword: (email: string) => Promise<void>;
}

export const authService: IAuthService = {
  createAuthUser,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  forgetPassword,
};
