export enum BookType {
  softCover,
  hardCover,
}

export enum BookModel {
  web,
  draw,
  collective,
}

export interface Product {
  code: string;
  name: string;
  price: number;
  bookType: BookType;
  bookModel: BookModel;
  bookAmount: number;
  productDescription: string;
  salesDescription: string;
  photoUrl: string;
  badge?: string;
}
